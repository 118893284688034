//https://www.iban.com/country-codes

export default [
    {
        name: "India",
        alpha2: "IN",
        alpha3: "IND",
        numeric: "356"
    },
    {
        name: "United States of America",
        alpha2: "US",
        alpha3: "USA",
        numeric: "840"
    },
    {
        name: "United States of America (Puerto Rico)",
        alpha2: "PR",
        alpha3: "PRI",
        numeric: "630"
    },
    {
        name: "Puerto Rico",
        alpha2: "PR",
        alpha3: "PRI",
        numeric: "630"
    },
    {
        name: "United Kingdom",
        alpha2: "GB",
        alpha3: "GBR",
        numeric: "826"
    },
    {
        name: "Philippines",
        alpha2: "PH",
        alpha3: "PHL",
        numeric: "608"
    },
    {
        name: "Australia",
        alpha2: "AU",
        alpha3: "AUS",
        numeric: "036"
    },
    {
        name: "Spain",
        alpha2: "ES",
        alpha3: "ESP",
        numeric: "724"
    },
    {
        name: "Bulgaria",
        alpha2: "BG",
        alpha3: "BGR",
        numeric: "100"
    },
    {
        name: "Poland",
        alpha2: "PL",
        alpha3: "POL",
        numeric: "616"
    },
    {
        name: "Malaysia",
        alpha2: "MY",
        alpha3: "MYS",
        numeric: "458"
    },
    {
        name: "Russian Federation",
        alpha2: "RU",
        alpha3: "RUS",
        numeric: "643"
    },
    {
        name: "Ukraine",
        alpha2: "UA",
        alpha3: "UKR",
        numeric: "804"
    },
    {
        name: "Germany",
        alpha2: "DE",
        alpha3: "DEU",
        numeric: "276"
    },
    {
        name: "Brazil",
        alpha2: "BR",
        alpha3: "BRA",
        numeric: "076"
    },
    {
        name: "Canada",
        alpha2: "CA",
        alpha3: "CAN",
        numeric: "124"
    },
    {
        name: "Romania",
        alpha2: "RO",
        alpha3: "ROU",
        numeric: "642"
    },
    {
        name: "France",
        alpha2: "FR",
        alpha3: "FRA",
        numeric: "250"
    },
    {
        name: "China",
        alpha2: "CN",
        alpha3: "CHN",
        numeric: "156"
    },
    {
        name: "Italy",
        alpha2: "IT",
        alpha3: "ITA",
        numeric: "380"
    },
    {
        name: "Costa Rica",
        alpha2: "CR",
        alpha3: "CRI",
        numeric: "188"
    },
    {
        name: "Mexico",
        alpha2: "MX",
        alpha3: "MEX",
        numeric: "484"
    },
    {
        name: "Slovakia",
        alpha2: "SK",
        alpha3: "SVK",
        numeric: "703"
    },
    {
        name: "Vietnam",
        alpha2: "VN",
        alpha3: "VNM",
        numeric: "704"
    },
    {
        name: "Singapore",
        alpha2: "SG",
        alpha3: "SGP",
        numeric: "702"
    },
    {
        name: "Argentina",
        alpha2: "AR",
        alpha3: "ARG",
        numeric: "032"
    },
    {
        name: "Denmark",
        alpha2: "DK",
        alpha3: "DNK",
        numeric: "208"
    },
    {
        name: "Japan",
        alpha2: "JP",
        alpha3: "JPN",
        numeric: "392"
    },
    {
        name: "Belgium",
        alpha2: "BE",
        alpha3: "BEL",
        numeric: "056"
    },
    {
        name: "Czech Republic",
        alpha2: "CZ",
        alpha3: "CZE",
        numeric: "203"
    },
    {
        name: "Netherlands",
        alpha2: "NL",
        alpha3: "NLD",
        numeric: "528"
    },
    {
        name: "Switzerland",
        alpha2: "CH",
        alpha3: "CHE",
        numeric: "756"
    },
    {
        name: "Sweden",
        alpha2: "SE",
        alpha3: "SWE",
        numeric: "752"
    },
    {
        name: "New Zealand",
        alpha2: "NZ",
        alpha3: "NZL",
        numeric: "554"
    },
    {
        name: "Hungary",
        alpha2: "HU",
        alpha3: "HUN",
        numeric: "348"
    },
    {
        name: "Egypt",
        alpha2: "EG",
        alpha3: "EGY",
        numeric: "818"
    },
    {
        name: "Lithuania",
        alpha2: "LT",
        alpha3: "LTU",
        numeric: "440"
    },
    {
        name: "United Arab Emirates",
        alpha2: "AE",
        alpha3: "ARE",
        numeric: "784"
    },
    {
        name: "Ireland",
        alpha2: "IE",
        alpha3: "IRL",
        numeric: "372"
    },
    {
        name: "Hong Kong",
        alpha2: "HK",
        alpha3: "HKG",
        numeric: "344"
    },
    {
        name: "Austria",
        alpha2: "AT",
        alpha3: "AUT",
        numeric: "040"
    },
    {
        name: "Saudi Arabia",
        alpha2: "SA",
        alpha3: "SAU",
        numeric: "682"
    },
    {
        name: "Portugal",
        alpha2: "PT",
        alpha3: "PRT",
        numeric: "620"
    },
    {
        name: "Norway",
        alpha2: "NO",
        alpha3: "NOR",
        numeric: "578"
    },
    {
        name: "Chile",
        alpha2: "CL",
        alpha3: "CHL",
        numeric: "152"
    },
    {
        name: "Thailand",
        alpha2: "TH",
        alpha3: "THA",
        numeric: "764"
    },
    {
        name: "Taiwan",
        alpha2: "TW",
        alpha3: "TWN",
        numeric: "158"
    },
    {
        name: "Tunisia",
        alpha2: "TN",
        alpha3: "TUN",
        numeric: "788"
    },
    {
        name: "Israel",
        alpha2: "IL",
        alpha3: "ISR",
        numeric: "376"
    },
    {
        name: "South Africa",
        alpha2: "ZA",
        alpha3: "ZAF",
        numeric: "710"
    },
    {
        name: "Colombia",
        alpha2: "CO",
        alpha3: "COL",
        numeric: "170"
    },
    {
        name: "Serbia",
        alpha2: "RS",
        alpha3: "SRB",
        numeric: "688"
    },
    {
        name: "Luxembourg",
        alpha2: "LU",
        alpha3: "LUX",
        numeric: "442"
    },
    {
        name: "Peru",
        alpha2: "PE",
        alpha3: "PER",
        numeric: "604"
    },
    {
        name: "Finland",
        alpha2: "FI",
        alpha3: "FIN",
        numeric: "246"
    },
    {
        name: "Brunei Darussalam",
        alpha2: "BN",
        alpha3: "BRN",
        numeric: "096"
    },
    {
        name: "Republic of Korea",
        alpha2: "KR",
        alpha3: "KOR",
        numeric: "410"
    },
    {
        name: "Indonesia",
        alpha2: "ID",
        alpha3: "IDN",
        numeric: "360"
    },
    {
        name: "Jordan",
        alpha2: "JO",
        alpha3: "JOR",
        numeric: "400"
    },
    {
        name: "Fiji",
        alpha2: "FJ",
        alpha3: "FJI",
        numeric: "242"
    },
    {
        name: "Morocco",
        alpha2: "MA",
        alpha3: "MAR",
        numeric: "504"
    },
    {
        name: "Nigeria",
        alpha2: "NG",
        alpha3: "NGA",
        numeric: "566"
    },
    {
        name: "Panama",
        alpha2: "PA",
        alpha3: "PAN",
        numeric: "591"
    },
    {
        name: "Qatar",
        alpha2: "QA",
        alpha3: "QAT",
        numeric: "634"
    },
    {
        name: "Greece",
        alpha2: "GR",
        alpha3: "GRC",
        numeric: "300"
    },
    {
        name: "Turkey",
        alpha2: "TR",
        alpha3: "TUR",
        numeric: "792"
    },
    {
        name: "Croatia",
        alpha2: "HR",
        alpha3: "HRV",
        numeric: "191"
    },
    {
        name: "Kazakhstan",
        alpha2: "KZ",
        alpha3: "KAZ",
        numeric: "398"
    },
    {
        name: "Cyprus",
        alpha2: "CY",
        alpha3: "CYP",
        numeric: "196"
    }
]