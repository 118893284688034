<template>
    <div ref="vsSlide" class="carousel__slide">
        <slot/>
    </div>
</template>

<script>
    export default {
        name: "CarouselSlide"
    }
</script>