<template>
    <div></div>
</template>

<script>
    export default {
        name: "ViewsProductsProxy",
        mounted() {

            this.$router.push({
                name: "products",
                params: {
                    grouping: this.$route.params.grouping,
                    grouping_id: this.$route.params.grouping_id
                }
            })
        },
    }
</script>