export default {
    required: "A value is required",
    mask: "The value is invalid",
    maxValue: "The maximum value is {maxValue}",
    regex: "The value is invalid",
    server: {
        DELEGATES_NOT_ENABLED: "Delegates are not enabled for this partner.",
        DELEGATE_DOES_NOT_EXIST: "Delegate does not exist",
        DELEGATE_UPDATE_PERMISSIONS_MISMATCH: "Cannot update a delegate that does not belong to you.",
        DELEGATE_DELETE_PERMISSIONS_MISMATCH: "Cannot delete a delegate that does not belong to you.",
        DELEGATE_ALREADY_EXISTS: "An existing delegate rule matching this criteria already exists.",
        DELEGATE_CANNOT_BE_SELF: "You cannot add yourself as a delegate."
    }
}