export default {
    message: {
        //     constraints  ordered
        default_everything_everything: "<p>To date, you have ordered everything available to you in Gear.</p><p>If you have a specific question about this, please click on the <a href='#' role='button' data-widget-trigger='{widgetOptions}'>Gear Support</a> link above and submit your question.</p>",
        default_everything_something: "<p>Previously ordered items are no longer shown.</p><p>If you have a specific question about this, please click on the <a href='#' role='button' data-widget-trigger='{widgetOptions}'>Gear Support</a> link above and submit your question.</p>",
        default_partial_everything: "<p>There are no additional products to order.</p><p>Did your situation change from when you first answered your equipment survey? Click <a href='{equipmentQuizLink}'><b>here</b></a> to review your equipment survey answers.</p>",
        default_partial_something: "<p>Previously ordered items are no longer shown.</p><p>Did your situation change from when you first answered your equipment survey? Click <a href='{equipmentQuizLink}'><b>here</b></a> to review your equipment survey answers.</p>",
        default_partial_noitems: "<p>Did your situation change from when you first answered your equipment survey? Click <a href='{equipmentQuizLink}'><b>here</b></a> to review your equipment survey answers.</p>",

        ehs_everything_everything: "<p>To date, you have ordered everything available to you in Gear.</p><p>Availability of products is based on the EHS survey. If you need additional support, please contact <a href='mailto:{supportEmail}'>{supportEmail}</a>.</p>",
        ehs_everything_something: "<p>Previously ordered items are no longer shown.</p><p>Availability of products is based on the EHS survey. If you need additional support, please contact <a href='mailto:{supportEmail}'>{supportEmail}</a>.</p>",
        ehs_partial_everything: "<p>To date, you have ordered everything available to you in Gear.</p><p>Availability of products is based on the EHS survey. If you need additional support, please contact <a href='mailto:{supportEmail}'>{supportEmail}</a>.</p>",
        ehs_partial_something: "<p>Previously ordered items are no longer shown.</p><p>Availability of products is based on the EHS survey. If you need additional support, please contact <a href='mailto:{supportEmail}'>{supportEmail}</a>.</p>",
        ehs_partial_noitems: "<p>Availability of products is based on the EHS survey. If you need additional support, please contact <a href='mailto:{supportEmail}'>{supportEmail}</a>.</p>",
    }
}