import sysend from "sysend"

export default {
    install(app, options = {}) {

        const broadcast = {
            messages: {
                refresh_addresses: "refresh_addresses"
            },
            on(name, callback) {

                sysend.on(name, callback);
            },
            off(name, callback) {

                sysend.off(name, callback);
            },
            post(name, data) {

                sysend.broadcast(name, data)
            }
        };

        app.provide('$broadcast', broadcast);
        app.config.globalProperties.$broadcast = broadcast;
    }
}