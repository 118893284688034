import {home} from "@/router/breadcrumbs";
import {PERMISSIONS} from "@/constants";

import ViewsErrors404 from "@/views/errors/ViewsErrors404";
import ViewsHome from "@/views/home/ViewsHome";
import ViewsMaintenance from "@/views/ViewsMaintenance";

export default {
    routes: [{
        path: "/",
        name: "home",
        component: ViewsHome,
        meta: {
            requireAuth: true,
            breadcrumbs: [{
                text: home.text
            }],
            permissions: [PERMISSIONS.EMPLOYEE]
        }
    }, {
        path: "/maintenance",
        name: "maintenance",
        component: ViewsMaintenance,
        meta: {}
    }, {
        path: "/support",
        name: "support",
        component: {
            beforeMount() {

                window.location.href = "/?wgtopen=true";
            }
        },
        meta: {}
    }, {
        path: "/:pathMatch(.*)*",
        component: ViewsErrors404
    }]
}