import ViewsRouter from '@/views/ViewsRouter'
import ViewsAddressVerified from "@/views/address/ViewsAddressVerified";

export default {
    routes: [{
        path: "/address",
        name: "address-parent",
        component: ViewsRouter,
        children: [{
            path: "verified",
            name: "address.verified",
            component: ViewsAddressVerified,
            meta: {}
        }]
    }]
}
