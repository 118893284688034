export default {
    unverified: "Unverified",
    verificationLink: "Resend Verification Email",
    buttons: {
        delete: {
            cta: "Delete",
            processing: "Deleting"
        }
    },
    addressDeleted: "Address has been deleted",
    verificationEmailSent: "Email has been sent",
    deleteAddressConfirmation: "Are you sure you want to delete this address?"
}