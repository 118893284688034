import {platform} from "@/api/platform";
import {retryRequest} from "@/helpers/request";

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        getCatalogMessage(context) {

            const userId = this.getters["user/id"];

            return retryRequest(() => platform.get(`/api/v2/user/${userId}/catalog-message`))
                .then(response => response.data.results);
        }
    }
}