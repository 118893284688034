<template>
    <img v-if="url" class="collection-item-image" :src="url" alt="" />
</template>

<script>
    export default {
        name: "CollectionItemImage",
        props: {
            url: String
        }
    }
</script>