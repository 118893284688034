export default {
    country: {
        type: "dropdown",
        wrapperCssClass: "offset-md-3 col-md-6",
        options: {
            source: "store",
            target: "db/countries/items",
            value: "alpha3",
            name: "name",
            filter: "quiz_filter_countries"
        },
        validation: {
            required: true
        },
        payload: {
            field: "country"
        }
    }
}