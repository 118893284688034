import ViewsQuiz from "@/views/quiz/ViewsQuiz";
import {PERMISSIONS} from "@/constants";

export default {
    routes: [{
        path: "/quiz/:page?",
        name: "quiz",
        component: ViewsQuiz,
        meta: {
            requireAuth: true,
            permissions: [PERMISSIONS.EMPLOYEE]
        }
    }]
}
