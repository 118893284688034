import submitted from "./survey/submitted";
import delivery from "./survey/delivery";
import deliveryfaq from "./survey/deliveryfaq";
import customer from "./survey/customer";

export default {
    submitted,
    delivery,
    deliveryfaq,
    customer,
}
