<template>
    <span v-if="title" class="collection-item-title">{{title}}</span>
</template>

<script>
    export default {
        name: "CollectionItemTitle",
        props: {
            title: String
        }
    }
</script>