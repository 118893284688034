import ViewsPrivacy from "@/views/privacy/ViewsPrivacy";

import {home} from "@/router/breadcrumbs";

export default {
    routes: [{
        path: "/privacy",
        name: "privacy",
        component: ViewsPrivacy,
        meta: {
            breadcrumbs: [home]
        }
    }]
}
