import ViewsSurveysView from "@/views/surveys/ViewsSurveysView";
import ViewsSurveysDelivery from "@/views/surveys/ViewsSurveysDelivery";

import {home} from "../breadcrumbs";

export default {
    routes: [{
        path: "/surveys/delivery",
        name: "surveys.delivery",
        component: ViewsSurveysDelivery,
        meta: {}
    }, {
        path: "/surveys/:survey_id",
        name: "surveys.view",
        component: ViewsSurveysView,
        meta: {
            requireAuth: true,
            breadcrumbs: [home]
        }
    }]
}
