<template>
    <div>
        <div class="d-flex">
            <div v-if="title" class="h1 flex-grow-1 mb-2">{{title}}</div>
            <router-link v-if="grouping.showMore"
                         class="flex-grow-0 small align-self-center mb-2"
                         :to="{name:'products',params:{grouping:'catalog',grouping_id:grouping.id}}">
                {{t('view')}}
            </router-link>
        </div>

        <p v-if="description" class="pb-2" v-html="description"></p>

        <products-list class="pb-2" :value="grouping.items" :grouping="grouping.type" :grouping-id="grouping.id"/>
    </div>
</template>

<script>

    import {getTranslationValue} from "@/i18n/utils/lang_overrides";
    import {sanitize} from "@/helpers/html";

    import ProductsList from "@/components/products/ProductsList";

    export default {
        name: "ProductsGrouping",
        components: {
            ProductsList
        },
        props: {
            grouping: {
                type: Object,
                required: true
            }
        },
        data() {

            return {
                t_path: "components.products.grouping"
            }
        },
        computed: {
            title() {

                return getTranslationValue(this.grouping, "title", this.$i18n);
            },
            description() {

                return sanitize(getTranslationValue(this.grouping, "description", this.$i18n));
            }
        }
    }
</script>