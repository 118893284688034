<template>
    <button type="button" class="collection-item"
            :class="{'collection-item-selected':selected}"
            :disabled="disabled"
            @click="toggle">
        <slot></slot>
    </button>
</template>

<script>
    export default {
        name: "CollectionItem",
        props: {
            modelValue: {
                required: true,
                type: [String, Number]
            },
            disabled: Boolean
        },
        emits: ['toggle'],
        data() {

            return {
                selected: false
            }
        },
        methods: {
            toggle() {

                this.$parent.toggle(this.modelValue).then(selected => this.selected = selected);
            }
        },
        mounted() {

            this.$parent.add(this, this.modelValue).then(selected => this.selected = selected);
        }
    }
</script>