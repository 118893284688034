export default {
    title: "Login Verification Required",
    header: "@:views.auth.confirm.title",
    message: "To continue, please check your Company email for an email verification link. Click the link in the email to proceed.",
    messageBeforeCta: "If you do not see the email, click",
    messageAfterCta: "to resend.",
    secondMessage: "If you still don't see the email, double-check your spam folder or contact us at <a href='mailto:{email}?subject={subject}'>{email}</a>.",
    subject: "Gear - TFA",
    buttons: {
        cta: "here",
        processing: "here"
    },
    headerAlreadyConfirmed: "Login Verification",
    messageAlreadyConfirmed: "You have already confirmed your email."
}