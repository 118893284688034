<template>
    <div>
        <div v-for="(modal, index) in modals" class="modals" :key="modal.id">
            <div class="modals-overlay" @click="close(index, $event)">
                <div class="modals-wrapper">
                    <div class="modals-modal"
                         :class="{'modals-modal-sm':modal.sm, 'modals-modal-md':modal.md, 'modals-modal-lg':modal.lg, 'modals-modal-xl':modal.xl, 'modals-modal-fluid':modal.fluid, 'modals-modal-transparent':modal.transparent}">
                        <button type="button"
                                @click="close(index, $event, true)"
                                class="modals-modal-close btn"><span class="icon-cancel"></span></button>
                        <div class="modals-modal-header" v-if="modal.title">
                            {{modal.title}}
                        </div>
                        <component :is="modal.component.name" v-bind="modal.props"
                                   @save="save(index, $event)"
                                   @close="close(index, $event, true)"></component>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import {mapGetters} from 'vuex'

    export default {
        name: "Modals",
        data() {

            return {};
        },
        watch: {
            modals(value) {

                document.body.style.overflow = 0 == value.length ? null : 'hidden';

            }
        },
        computed: {
            ...mapGetters({
                modals: 'modals/items'
            })
        },
        methods: {
            save(index, result) {

                const modal = this.modals[index];

                if (modal) {

                    modal._ok(result);
                }
            },
            close(index, event, force) {

                if (force || event.target.classList.contains("modals-wrapper")) {

                    this.$store.dispatch('modals/reject', index);
                    this.$store.dispatch('modals/splice', {index, count: 1});
                }
            }
        },
        created() {

            document.body.addEventListener('keydown', (event) => {

                const code = event.keyCode || event.which;

                if (27 === code && 0 < this.modals.length) {

                    // Get last model
                    if (false !== this.modals[this.modals.length - 1].esc) {

                        this.close(this.modals.length - 1, null, true);
                    }

                }

            });
        }
    }
</script>