import {order} from "@/api/order";
import {retryRequest} from "@/helpers/request";

const filterAddresses = (addresses, country) => addresses.filter(address => address.country == country);

let REFRESH_INTERVAL;

const CONFIG_FIELD_PERSONAL_EMAIL = {
    field: "personalEmail",
    type: "text",
    display: {
        label: "Personal Email",
        placeholder: "Personal Email"
    },
    validation: {
        required: true,
        email: true
    }
};
const CONFIG_FIELD_MANAGER_EMAIL = {
    field: "managerEmail",
    type: "text",
    display: {
        label: "Manager Email",
        placeholder: "Manager Email"
    },
    validation: {
        required: true,
        email: true
    }
};
const CONFIG_FIELD_MANAGER_NAME = {
    field: "managerName",
    type: "text",
    display: {
        label: "Manager's Name",
        placeholder: "Manager's Name"
    },
    validation: {
        required: true
    }
};

export default {
    namespaced: true,
    state: {
        config: null,
        header: null,
        denyAddressCreation: null,
        items: []
    },
    getters: {
        items: state => state.items,
        findItem: state => id => state.items.find(address => address._id == id),
        config: state => state.config,
        header: state => state.header,
        denyAddressCreation: state => state.denyAddressCreation,
        configReturn: state => [CONFIG_FIELD_PERSONAL_EMAIL, CONFIG_FIELD_MANAGER_NAME, CONFIG_FIELD_MANAGER_EMAIL]
            .concat(state.config)
    },
    mutations: {
        setItems: (state, items) => state.items = items,
        addItem: (state, item) => state.items.push(item),
        updateItem: (state, item) => state.items[state.items.findIndex(existing => existing._id == item._id)] = item,
        setConfig: (state, config) => state.config = config,
        setHeader: (state, header) => state.header = header,
        setDenyAddressCreation: (state, denyAddressCreation) => state.denyAddressCreation = denyAddressCreation
    },
    actions: {
        fetch(context) {

            const userId = this.getters["user/id"];
            const country = this.getters["user/profile/country"];

            return Promise.all([
                retryRequest(() => order.get(`/users/${userId}/addresses`), undefined, undefined, `get:/users/${userId}/addresses`),
                retryRequest(() => order.get(`/users/${userId}/addresses/config`), undefined, undefined, `get:/users/${userId}/addresses/config`)
            ]).then(responses => {

                const [addressesResponse, configResponse] = responses;

                context.commit("setItems", filterAddresses(addressesResponse.data.results, country));
                context.commit("setConfig", configResponse.data.results.config);
                context.commit("setHeader", configResponse.data.results.header);
                context.commit("setDenyAddressCreation", configResponse.data.results.denyAddressCreation);
            });
        },
        post(context, model) {

            const userId = this.getters["user/id"];

            return retryRequest(() => order.post(`/users/${userId}/addresses`, model))
                .then(response => {

                    const address = response.data.results;
                    context.commit("addItem", address);

                    return address;
                });
        },
        put(context, model) {

            const userId = this.getters["user/id"];

            return retryRequest(() => order.put(`/users/${userId}/addresses/${model._id}`, model))
                .then(response => {

                    const address = response.data.results;
                    context.commit("updateItem", address);

                    return address;
                });
        },
        clear(context) {

            context.commit("setItems", []);
        },
        confirm(context, addressId) {

            const userId = this.getters["user/id"];

            return retryRequest(() => order.post(`/users/${userId}/addresses/${addressId}/verification/send`));
        },
        delete(context, addressId) {

            const userId = this.getters["user/id"];

            return order
                .delete(`/users/${userId}/addresses/${addressId}`)
                .then(() => context.dispatch("fetch"));
        },
        refresh(context, options) {

            if (options.start && options.interval) {

                const userId = this.getters["user/id"];
                const country = this.getters["user/profile/country"];
                const callback = () => retryRequest(() => order.get(`/users/${userId}/addresses`))
                    .then(response => context.commit("setItems", filterAddresses(response.data.results, country)));

                callback();

                clearInterval(REFRESH_INTERVAL);
                REFRESH_INTERVAL = setInterval(callback, options.interval);

                return true;
            }

            clearInterval(REFRESH_INTERVAL);

            return false;
        }
    },
    modules: {},
}