import ViewsCatalog from "@/views/catalog/ViewsCatalog";

import {home} from "@/router/breadcrumbs";
import {PERMISSIONS} from "@/constants";

export default {
    routes: [{
        path: "/catalog",
        name: "catalog",
        component: ViewsCatalog,
        meta: {
            requireAuth: true,
            breadcrumbs: [home, {
                text: "catalog"
            }],
            permissions: [PERMISSIONS.EMPLOYEE]
        }
    }]
}
