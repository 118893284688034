import sanitizeHtml from "sanitize-html";

export function sanitize(html, allowedTags) {

    if (!html) {

        return html;
    }

    return sanitizeHtml(html, {
        allowedTags: allowedTags
            ? allowedTags
            : ["b", "i", "em", "strong", "br", "ul", "ol", "li", "a"],
    });
}