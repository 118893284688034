<template>
    <div class="modal-confirm">
        <div class="modals-modal-header" v-if="header">
            {{header}}
        </div>
        <div class="modals-modal-body">

            <div v-if="message" class="modal-confirm-message">{{message}}</div>

            <div class="text-center" v-if="tip">
                <small>{{tip}}</small>
            </div>
        </div>
        <div class="modals-modal-footer">
            <button type="button"
                    @click="$emit('save')"
                    ref="yes"
                    class="btn btn-primary">{{t('yes')}}
            </button>

            <button type="button"
                    @click="$emit('close')"
                    class="btn btn-primary">{{t('no')}}
            </button>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ModalConfirm",
        props: {
            header: {
                type: String
            },
            message: {
                type: String
            },
            tip: {
                type: String
            }
        },
        data() {

            return {
                t_path: "modal.confirm"
            }
        },
        mounted() {

            if (this.$refs.yes) {

                this.$refs.yes.focus();
            }
        }
    }
</script>