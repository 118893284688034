<template>
    <div :class="class">

        <input type="radio"
               :value="value"
               :class="inputClass"
               :disabled="disabled"
               :tabindex="tabindex"
               :id="labelFor"
               :name="name"
               :focus="focus"
               :checked="model==value"
               @change="change($event.target.checked)"
               ref="element">

        <label :class="labelClass" :for="labelFor">
            <slot>{{label}}</slot>
        </label>

    </div>
</template>

<script>

    import form from "@/mixins/form";

    export default {
        name: "FormRadio",
        mixins: [form],
        props: {
            value: {},
            model: {},
            name: {
                type: String,
            },
            label: {
                type: String
            },
            class: {
                type: String,
                default: "form-check"
            },
            labelClass: {
                type: String,
                default: "form-check-label"
            },
            inputClass: {
                type: String,
                default: "form-check-input form-check-inline"
            }
        },
        methods: {
            change(isChecked) {

                if (isChecked) {

                    this.$emit('input', this.value);
                }
            }
        },
    }
</script>