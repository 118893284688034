export default {
    view: {
        success: {
            header: "Survey Submitted",
            message: "Thank you for your feedback."
        },
        productChoice: {
            cta: "Save",
            processing: "Saving"
        },
        addressForm: {
            cta: "Save",
            processing: "Saving"
        }
    },
    delivery: {
        title: "Delivery survey",
        message: "Have you received all of the items listed below?",
        cta: {
            yes: "Yes",
            no: "No",
            processing: "Saving"
        }
    }
}