<template>
    <div class="modal-message">
        <div class="modals-modal-body">
            <div class="text-center">{{message}}</div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ModalMessage",
        props: {
            message: {
                type: String
            },
            timeout: {
                type: Number,
                default: 3000
            }
        },
        mounted() {

            setTimeout(() => this.$emit("close"), this.timeout);
        }
    }
</script>