export function getBranchById(items, id) {

    let child;

    for (let i = 0; i < items.length; i++) {

        if (items[i].id == id) {

            return items[i];
        }

        if ("undefined" !== typeof items[i].children) {

            child = getBranchById(items[i].children, id);

            if (child) {

                return child;
            }
        }
    }

    return null;
}

export function extendTreeWithParentId(items, parentId = null) {

    items.forEach(item => {

        item.parent_id = parentId;

        if ("undefined" !== typeof item.children) {

            extendTreeWithParentId(item.children, item.id);
        }
    });
    return items;
}

export function getParentsChain(items, item) {

    items = extendTreeWithParentId(items);

    let chain = [];
    let parent = item.parent_id ? getBranchById(items, item.parent_id) : null;

    while (parent) {

        chain.unshift(parent);

        parent = parent.parent_id ? getBranchById(items, parent.parent_id) : null;
    }

    return chain;
}