const TAG_VIRTUAL_WORKER = "virtual";
const TAG_NON_VIRTUAL_WORKER = "non-virtual";

/**
 * Page object supports following structure
 * {
 *     // Root
 *     id: string - page identifier
 *
 *     // Profile
 *     allowedVersion: array - array of profile versions. Profile version respects ordering
 *
 *     // Text properties (see src/quiz/options.page.text)
 *     [text.property]: string|object
 *           : string - option is translated with appropriate translation
 *           : object - dynamic text
 *           {
 *               "translation.key": true - if this option is true then it will be used if no other conditions match
 *               "translation.key": {
 *                   "component": ["value 1", "value 2"]
 *               } - if any of component values match then translation will be used
 *               "translation.key": [{component:[...]},{component:[...]}] - if all conditions match then translation will be used
 *           }
 *
 *     display: boolean|object|array
 *           : boolean - show component
 *           : object - dynamic display
 *           {
 *                 "component": ["value 1", "value 2"]
 *           } - if any of component values match then item would be displayed
 *           : [{component:[...]},{component:[...]}] - if all conditions match then item will be displayed
 *     hide: boolean|object|array
 *           : boolean - hide component
 *           : object - dynamic display
 *           {
 *                 "component": ["value 1", "value 2"]
 *           } - if any of component values match then item would be hidden
 *           : [{component:[...]},{component:[...]}] - if all conditions match then item will be hidden
 *
 *      components: [
 *          {
 *              name: string - component name (see src/quiz/components)
 *              display: boolean|object|array
 *                  : boolean - show component
 *                  : object - dynamic display
 *                  {
 *                      "component": ["value 1", "value 2"]
 *                  } - if any of component values match then item would be displayed
 *                  : [{component:[...]},{component:[...]}] - if all conditions match then item will be displayed
 *              hide: boolean|object|array
 *                  : boolean - hide component
 *                  : object - dynamic display
 *                  {
 *                      "component": ["value 1", "value 2"]
 *                  } - if any of component values match then item would be hidden
 *                  : [{component:[...]},{component:[...]}] - if all conditions match then item will be hidden
 *
 *          }
 *      ] - array of components
 * }
 */

export default [
    {
        id: null,
        allowedVersion: ["1.0", "1.1"],
        title: "title",
        components: [{
            name: "country"
        }],
    }
]