import {DateTime} from "luxon";

export function offsetUTCTimestampToLocal(timestamp) {

    return timestamp ? timestamp - DateTime.local().offset * 60 * 1000 : timestamp;
}

export function offsetLocalTimestampToUTC(timestamp) {

    return timestamp ? timestamp + DateTime.fromMillis(timestamp).offset * 60 * 1000 : timestamp;
}