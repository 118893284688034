const TAG_VIRTUAL_WORKER = "virtual";
const TAG_NON_VIRTUAL_WORKER = "non-virtual";

/**
 * Page object supports following structure
 * {
 *     // Root
 *     id: string - page identifier
 *
 *     // Profile
 *     allowedVersion: array - array of profile versions. Profile version respects ordering
 *
 *     // Text properties (see src/quiz/options.page.text)
 *     [text.property]: string|object
 *           : string - option is translated with appropriate translation
 *           : object - dynamic text
 *           {
 *               "translation.key": true - if this option is true then it will be used if no other conditions match
 *               "translation.key": {
 *                   "component": ["value 1", "value 2"]
 *               } - if any of component values match then translation will be used
 *               "translation.key": [{component:[...]},{component:[...]}] - if all conditions match then translation will be used
 *           }
 *
 *     display: boolean|object|array
 *           : boolean - show component
 *           : object - dynamic display
 *           {
 *                 "component": ["value 1", "value 2"]
 *           } - if any of component values match then item would be displayed
 *           : [{component:[...]},{component:[...]}] - if all conditions match then item will be displayed
 *     hide: boolean|object|array
 *           : boolean - hide component
 *           : object - dynamic display
 *           {
 *                 "component": ["value 1", "value 2"]
 *           } - if any of component values match then item would be hidden
 *           : [{component:[...]},{component:[...]}] - if all conditions match then item will be hidden
 *
 *      components: [
 *          {
 *              name: string - component name (see src/quiz/components)
 *              display: boolean|object|array
 *                  : boolean - show component
 *                  : object - dynamic display
 *                  {
 *                      "component": ["value 1", "value 2"]
 *                  } - if any of component values match then item would be displayed
 *                  : [{component:[...]},{component:[...]}] - if all conditions match then item will be displayed
 *              hide: boolean|object|array
 *                  : boolean - hide component
 *                  : object - dynamic display
 *                  {
 *                      "component": ["value 1", "value 2"]
 *                  } - if any of component values match then item would be hidden
 *                  : [{component:[...]},{component:[...]}] - if all conditions match then item will be hidden
 *
 *          }
 *      ] - array of components
 * }
 */

export default [
    {
        id: "workplace",
        allowedVersion: ["1.0", "1.1"],
        title: "title",
        description: "description",
        align: {
            title: "center",
            description: "center",
            text: "center",
        },
        display: {
            country: ["USA", "IND", "CAN", "BRA"]
        },
        components: [{
            name: "workplace"
        }]
    },
    {
        id: "equipmentsurvey",
        allowedVersion: ["1.0"],
        title: "title",
        description: "description",
        info: "info",
        align: {
            title: "center",
            description: "left",
            text: "left",
            info: "left"
        },
        display: {
            country: ["USA", "IND", "CAN", "BRA"]
        }
    },
    {
        id: "priority",
        allowedVersion: ["1.0"],
        title: "title",
        description: "description",
        align: {
            title: "center",
            description: "center",
            text: "center"
        },
        display: {
            country: ["USA", "IND", "CAN", "BRA"]
        },
        components: [{
            name: "priority"
        }, {
            name: "secondaryPriority",
            hide: {
                priority: ["Nothing Needed"]
            }
        }, {
            name: "priorityDetails",
            display: {
                priority: ["Something Else"]
            }
        }, {
            name: "secondaryPriorityDetails",
            display: [{
                priority: [
                    "Monitor",
                    "Docking Station",
                    "Keyboard/Mouse",
                    "Web Cam",
                    "Headset",
                    "Office Chair",
                    "Desk",
                    "Adapters",
                    "Something Else"
                ]
            }, {
                secondaryPriority: ["Something Else"]
            }]
        }]
    },
    {
        id: "additional-questions",
        allowedVersion: ["1.0", "1.1"],
        title: "title",
        align: {
            title: "center",
            description: "center",
            text: "center",
            info: "center"
        },
        display: {
            country: ["USA", "IND", "CAN", "BRA"]
        },
        components: [{
            name: "improveWorkspace"
        }, {
            name: "improveRemoteWorking"
        }]
    }
]