<template>
    <div :class="class">

        <label v-if="label" :for="labelFor">{{label}}<span v-if="_required" class="label-required">*</span></label>

        <div class="btn-group form-item-selector">
            <button v-for="option in options" :key="option[optionValue]" type="button"
                    class="btn btn-outline-secondary btn-light"
                    :class="{active:modelValue==option[optionValue]}"
                    :disabled="disabled"
                    @click="select(option[optionValue])">
                {{option[optionName]}}
            </button>
        </div>

        <slot name="tip"></slot>

        <div v-for="error in _errors" :key="error" class="d-block invalid-feedback">
            <span class="icon-attention-circled"></span>
            {{error}}
        </div>

    </div>
</template>

<script>

    import form from "@/mixins/form";

    export default {
        name: "FormItemSelector",
        mixins: [form],
        props: {
            modelValue: {},
            label: {
                type: String
            },
            class: {
                type: String,
                default: "form-group"
            },
            options: {
                type: Array,
                required: true
            },
            optionValue: {
                type: String,
                default: "value"
            },
            optionName: {
                type: String,
                default: "name"
            }
        },
        emits: ["update:modelValue"],
        methods: {
            select(value) {

                if (this.modelValue == value) {

                    return this.$emit("update:modelValue", null);
                }

                return this.$emit("update:modelValue", value);
            }
        }
    }
</script>