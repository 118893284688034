export default {
    props: {
        maxCharacters: Number
    },
    computed: {
        showCharacterCounter() {

            return 0 < this.maxCharacters;
        }
    }
}