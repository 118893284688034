export default {
    menu: {
        approvals: "Approvals",
        delegates: "Delegates"
    },
    approvals: {
        title: "Approval",
        error: "An error occurred",
        saved: "Approvals submitted",
        noApprovals: "Nothing to approve",
        review: "Please review and edit cost center if incorrect",
        priorApprovals: "Prior&nbsp;approvals",
        noOrders: "Nothing to approve",
        noPriorApprovals: "No prior approvals",
        approved: "Approved",
        notApproved: "Not Approved",
        confirm: "There are still orders with pending approvals. Do you wish to continue with your current changes?",
        manager: "Manager",
        orders: {
            name: "Name",
            date: "Order Date",
            details: "Order Details",
            cost: "Cost",
            costCenter: "Cost Center",
            approval: "Approval"
        },
        model: {
            reason: {
                placeholder: "Reason"
            },
            approve: {
                yes: {
                    label: "Yes"
                },
                no: {
                    label: "No"
                }
            }
        },
        buttons: {
            submit: {
                text: "Submit",
                processing: "Processing"
            }
        }
    },
    delegates: {
        title: "Delegates",
        error: "An error occurred",
        noDelegates: "No items",
        delegateDelete: "Delegate has been deleted",
        confirm: {
            delete: "Delete delegate?"
        },
        delegates: {
            email: "Email",
            startDate: "Start Date",
            endDate: "End Date",
        },
        buttons: {
            add: {
                text: "Add Delegate"
            }
        }
    }
}