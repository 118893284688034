import items from "./countries/items";

export default {
    namespaced: true,
    state: {
        items,
    },
    getters: {
        items: state => state.items.sort((a, b) => a.name.localeCompare(b.name))
    }
}