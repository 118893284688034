import {alphaNum, helpers, maxLength, maxValue, minLength, minValue, required} from "@vuelidate/validators";

import {getRegexFromMask} from "@/plugins/maska/tokens";

export default {
    methods: {
        validator_createDynamicMask(mask) {

            if (!mask) {

                return null;
            }

            return (value) => {

                if (!value) {

                    return true;
                }

                const masks = Array.isArray(mask) ? mask : [mask];

                return masks.reduce((isValid, mask) => {

                    if (isValid) {

                        return true;
                    }

                    const regex = getRegexFromMask(mask);

                    return regex.test(value);
                }, false);
            }
        },
        validator_applyDynamicMask(mask, result) {

            if (mask) {

                result.mask = this.validator_createDynamicMask(mask);
            }

            return result;
        },
        validator_createDynamicValidationRules(validation = {}, rules = {}) {

            if (validation) {

                if (validation.required) {

                    rules["required"] = required;
                }

                if (validation.minValue) {

                    rules["minValue"] = minValue(validation.minValue);
                }

                if (validation.maxValue) {

                    rules["maxValue"] = maxValue(validation.maxValue);
                }

                if (validation.minLength) {

                    rules["minLength"] = minLength(validation.minLength);
                }

                if (validation.maxLength) {

                    rules["maxLength"] = maxLength(validation.maxLength);
                }

                if (validation.alphaNum) {

                    rules["alphaNum"] = alphaNum;
                }

                if (validation.regex) {

                    rules["regex"] = helpers.regex(new RegExp(validation.regex));
                }
            }

            return rules;
        }
    }
}