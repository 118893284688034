<template>
    <div class="row">
        <div v-for="category in categories" :key="category._id" class="col-12 col-md-6 col-lg-4 pb-3">
            <categories-list-category :category="category" :grouping="grouping"/>
        </div>
    </div>
</template>

<script>

    import CategoriesListCategory from "@/components/categories/CategoriesListCategory";

    export default {
        name: "CategoriesList",
        components: {
            CategoriesListCategory
        },
        props: {
            categories: {
                type: Array,
                required: true
            },
            grouping: {
                type: String,
                required: true
            }
        }
    }
</script>