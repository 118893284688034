<template>
    <div v-if="isMaintenance" :data-skin="reskinning_style">
        <page-navbar-maintenance/>
        <router-view :key="$route.fullPath"/>
    </div>
    <div v-else-if="!loaded" :data-skin="reskinning_style" class="p-3">
        <loading/>
    </div>
    <div class="app-body" ref="app" :data-skin="reskinning_style" v-else>
        <page-navbar/>
        <div class="page-content">
            <banners/>
            <cart-review-order/>
            <breadcrumbs/>
            <div class="py-3">
                <router-view :key="$route.fullPath"/>
            </div>
        </div>
        <page-footer v-if="footer"/>
    </div>
    <modals :data-skin="reskinning_style"></modals>
    <a v-if="widget_displayWidgetButton" href="#" class="d-none" :data-widget-trigger="widget_optionsJson">sw</a>
</template>

<script>

    import {mapGetters} from "vuex"

    import {isUnauthorizedResponse} from "./api/_api";
    import {AUTOLOGIN_ENABLED} from "@/constants";
    import {USER_TOKEN_STORAGE_KEY} from "@/api/storage";
    import mixin_widget from "@/mixins/widget";
    import mixin_reskinning from "@/mixins/reskinning";
    import pageDestination from "@/helpers/pageDestination";
    import {startAutoLogin} from "@/helpers/auth";

    import PageFooter from "@/components/Footer";
    import PageNavbar from "@/components/PageNavbar"
    import PageNavbarMaintenance from "@/components/PageNavbarMaintenance"
    import Breadcrumbs from "@/components/Breadcrumbs";
    import CartReviewOrder from "@/components/cart/CartReviewOrder";
    import Banners from "@/components/Banners";

    export default {
        mixins: [mixin_widget, mixin_reskinning],
        components: {
            Banners,
            CartReviewOrder,
            Breadcrumbs,
            PageFooter,
            PageNavbar,
            PageNavbarMaintenance
        },
        data() {

            return {
                loaded: false,
                footer: 1 == process.env.VUE_APP_FOOTER
            }
        },
        computed: {
            ...mapGetters({
                addresses: "user/address/items",
                orders: "user/orders/items",
                language: "i18n/language",
            }),
            isMaintenance() {

                return "maintenance" === this.$route.name;
            },
            isLoggedIn() {

                return !!this.$store.getters["user/token"];
            }
        },
        methods: {
            handlePageDestinationParameter() {

                const targetRouterName = pageDestination.getPageDestination(this.$route.query);

                if (targetRouterName) {

                    this.$router.push({
                        name: targetRouterName,
                        query: this.$route.query
                    });
                }
            },
            handleUnauthorizedRequest() {

                // Remove token from selected storage
                this.$storage.remove(USER_TOKEN_STORAGE_KEY);

                // Remove unauthorized interceptors
                this.$platform.disableUnauthorizedInterceptor();
                this.$order.disableUnauthorizedInterceptor();
                this.$delivery.disableUnauthorizedInterceptor();

                // Logout user
                this.$store.dispatch("user/logout");

                if (true === AUTOLOGIN_ENABLED) {

                    // Start auto login process
                    startAutoLogin({
                        targeturi: this.$route.fullPath
                    });
                } else {

                    window.location = this.$router.resolve({name: "auth.login", query}).fullPath;
                }
            },
            listenMessage() {

                this.$broadcast.on(this.$broadcast.messages.refresh_addresses, () => {

                    if (this.$store.getters["user/isAuthenticated"] && this.$store.getters["user/country"] && this.$store.getters["user/profile/equipment_submitted"]) {

                        this.$store.dispatch("user/address/fetch");
                    }
                });
            }
        },
        watch: {
            $route(to) {

                this.breadcrumbs_processRouteMeta(to);
            },
            "$i18n.locale": {
                immediate: true,
                handler() {

                    this.breadcrumbs_processRouteMeta();
                    this.$store.dispatch("picker/setLocale", this.$i18n.locale);
                }
            },
            isLoggedIn() {

                this.widget_refresh();
            },
            orders() {

                this.widget_refresh();
            },
            addresses() {

                this.widget_refresh();
            },
            language() {

                this.widget_refresh();
            },
        },
        mounted() {

            if (this.$route.name != "maintenance") {

                this.$order.setUnauthorizedInterceptor(this.handleUnauthorizedRequest);
                this.$platform.setUnauthorizedInterceptor(this.handleUnauthorizedRequest);
                this.$delivery.setUnauthorizedInterceptor(this.handleUnauthorizedRequest);

                this.$store.dispatch("i18n/fetch").then(language => {

                    this.$i18n.locale = language.value;

                    // Set user signed
                    const auth = this.$storage.get(USER_TOKEN_STORAGE_KEY);

                    if (auth) {

                        this.$order.setToken(auth.token);
                        this.$platform.setToken(auth.token);
                        this.$delivery.setToken(auth.token);

                        this.$store.dispatch("user/fetch", auth).then(() => {

                            const forceSurvey = this.$store.getters["user/surveys/items"].find(i => true === i.forced);

                            if (forceSurvey) {

                                return this.$router.push({
                                    name: "surveys.view",
                                    params: {
                                        survey_id: forceSurvey._id
                                    }
                                });
                            }

                            this.handlePageDestinationParameter();

                        }).catch(error => {

                            if (isUnauthorizedResponse(error)) {

                                return this.handleUnauthorizedRequest();
                            }

                            if (error.response) {

                                if (500 <= error.response.status) {

                                    return this.$router.push({name: "errors.500"});
                                }

                                if (500 > error.response.status && 400 <= error.response.status) {

                                    return this.$router.push({name: "errors.400"});
                                }
                            }

                        }).finally(() => this.loaded = true);
                    } else {

                        this.loaded = true;

                        this.handlePageDestinationParameter();
                    }
                });
            }

            this.reskinning_init();

            this.listenMessage();

            this.widget_refresh();
        }
    }
</script>

<style lang="scss">

    html,
    body,
    #app {
        height: 100%;
        min-height: 100%;
        min-height: 100vh;
    }

    body {
        #app {
            .app-body {
                min-height: 100%;
                flex-grow: 1;
                display: flex;
                flex-direction: column;

                .page-content {
                    flex: 1 0 auto;
                }
            }
        }
    }
</style>
