import ViewsApproval from "@/views/approval/ViewsApproval";
import ViewsDelegate from "@/views/approval/ViewsDelegate";

import {PERMISSIONS} from "@/constants";

export default {
    routes: [{
        path: "/approvals",
        name: "approvals",
        component: ViewsApproval,
        meta: {
            requireAuth: true,
            permissions: [PERMISSIONS.MANAGER]
        }
    }, {
        path: "/approvals/delegate",
        name: "approvals.delegate",
        component: ViewsDelegate,
        meta: {
            requireAuth: true,
            permissions: [PERMISSIONS.MANAGER]
        }
    }]
}