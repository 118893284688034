export default {
    methods: {
        quiz_profile_getAllowedVersion(country, config, initialVersion = "1.0") {

            if (!country || !config) {

                return initialVersion;
            }

            if (config[country]) {

                return config[country];
            }

            // TODO: double check default value property
            return config.default;
        }
    }
}