import ViewsRouter from '@/views/ViewsRouter'
import ViewsReturn from "@/views/return/ViewsReturn";
import {PERMISSIONS} from "@/constants";

export default {
    routes: [{
        path: "/returns",
        name: "return-parent",
        component: ViewsRouter,
        children: [{
            path: "",
            name: "return.return",
            component: ViewsReturn,
            meta: {
                requireAuth: true,
                permissions: [PERMISSIONS.EMPLOYEE]
            }
        }]
    }]
}
