import axios from "axios";

export function create() {

    let unauthorizedInterceptorCallback, unauthorizedInterceptorIndex;

    const api = axios.create({});

    api.setToken = function (token) {

        this.defaults.headers["Authorization"] = token;
    };

    api.setBaseUrl = function (url) {

        this.defaults.baseURL = url;
    };

    api.disableUnauthorizedInterceptor = () => {

        if ("undefined" !== typeof unauthorizedInterceptorIndex && null !== unauthorizedInterceptorIndex) {

            api.interceptors.response.eject(unauthorizedInterceptorIndex)
        }
    };

    api.enableUnauthorizedInterceptor = () => {

        if (unauthorizedInterceptorCallback) {

            unauthorizedInterceptorIndex = api.interceptors.response.use(undefined, error => {

                if (error.response && 401 === error.response.status) {

                    unauthorizedInterceptorCallback(error);
                }

                return Promise.reject(error);
            });
        }

    };

    api.setUnauthorizedInterceptor = callback => {

        unauthorizedInterceptorCallback = callback ? callback : unauthorizedInterceptorCallback;

        api.enableUnauthorizedInterceptor();
    };

    api.getUnauthorizedInterceptorCallback = () => {

        return unauthorizedInterceptorCallback;
    };

    return api;
}

/**
 * Checks for error is request error and request is not authorized
 * @param error
 * @returns {Response<any, Record<string, any>, number> | AxiosResponse<T> | AxiosInterceptorManager<AxiosResponse> | any | boolean}
 */
export function isUnauthorizedResponse(error) {

    return error.response && 401 === error.response.status;
}

