import ViewsTerms from "@/views/terms/ViewsTerms";

import {home} from "@/router/breadcrumbs";

export default {
    routes: [{
        path: "/terms",
        name: "terms",
        component: ViewsTerms,
        meta: {
            breadcrumbs: [home]
        }
    }]
}
