<template>
    <div :class="class">

        <label v-if="label" :for="labelFor">{{label}}<span v-if="_required" class="label-required">*</span></label>

        <div class="input-group" :class="{'is-invalid':isInvalid,'is-valid':isValid}">
            <div class="input-group-prepend">
                <slot name="before"></slot>
            </div>

            <input v-maska="mask"
                   :value="modelValue"
                   :type="type"
                   :id="labelFor"
                   :placeholder="placeholder"
                   :autocomplete="autocomplete"
                   :class="[{'is-invalid':isInvalid,'is-valid':isValid}, textboxClass]"
                   :disabled="disabled"
                   :tabindex="tabindex"
                   :pattern="pattern"
                   :min="min"
                   :max="max"
                   :maxlength="maxlength?maxlength:maxCharacters"
                   :minlength="minlength"

                   @input="$emit('update:modelValue', $event.target.value)"

                   ref="element"/>

            <div class="input-group-append">
                <slot name="after"></slot>
            </div>
        </div>

        <div v-if="showCharacterCounter&&modelValue" class="text-right small">
            <small>{{modelValue.length}}/{{maxCharacters}}</small>
        </div>

        <slot name="tip"></slot>

        <div v-for="error in _errors" :key="error" class="invalid-feedback">
            <span class="icon-attention-circled"></span>
            {{error}}
        </div>

    </div>
</template>

<script>

    import form from "@/mixins/form";
    import character_counter from "@/mixins/character_counter";

    export default {
        name: "FormTextboxGroup",
        mixins: [form, character_counter],
        props: {
            modelValue: {},
            label: {
                type: String
            },
            type: {
                type: String,
                default: "text"
            },
            autocomplete: {
                type: String
            },
            pattern: {
                type: String
            },
            min: {
                type: Number
            },
            max: {
                type: Number
            },
            minlength: {
                type: Number
            },
            maxlength: {
                type: Number
            },
            textboxClass: {
                type: String,
                default: "form-control"
            },
            class: {
                type: String,
                default: "form-group"
            },
            mask: {
                type: [String, Array]
            }
        },
        emits: ['update:modelValue']
    }
</script>