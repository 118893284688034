import {delivery} from "@/api/delivery";
import {retryRequest} from "@/helpers/request";

export const types = {
    product_choice: "product_choice",
    address_form: "address_form",
    pending_approvals: "pending_approvals"
};

export default {
    namespaced: true,
    state: {
        hide: null,
        items: [],
    },
    getters: {
        items: state => state.items.filter(item => !item._id || item._id != state.hide)
    },
    mutations: {
        setItems: (state, items) => state.items = items,
        setHide: (state, hide) => state.hide = hide
    },
    actions: {
        fetch: context => retryRequest(() => delivery.get("/adhoc-survey"))
            .then(response => context.commit("setItems", response.data.results)),
        view: (context, surveyId) => delivery.put(`/adhoc-survey/${surveyId}/views`, {}),
        post: (context, model) => delivery.put(`/adhoc-survey/${model.survey_id}`, model),
        hide: (context, hide) => context.commit("setHide", hide),
        clear: context => context.commit("setItems", [])
    }
}