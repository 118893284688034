import ViewsRouter from '@/views/ViewsRouter'
import ViewsOrderSubmitted from "@/views/order/ViewsOrderSubmitted";
import ViewsOrderSubmit from "@/views/order/ViewsOrderSubmit";

import {home} from "@/router/breadcrumbs";
import {PERMISSIONS} from "@/constants";

export default {
    routes: [{
        path: "/order",
        name: "order-parent",
        component: ViewsRouter,
        children: [{
            path: "",
            name: "order.submit",
            component: ViewsOrderSubmit,
            meta: {
                requireAuth: true,
                breadcrumbs: [home, {
                    text: "order.submit"
                }],
                permissions: [PERMISSIONS.EMPLOYEE]
            }
        }, {
            path: "submitted",
            name: "order.submitted",
            component: ViewsOrderSubmitted,
            meta: {
                requireAuth: true,
                permissions: [PERMISSIONS.EMPLOYEE]
            }
        }]
    }]
}
