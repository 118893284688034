const countryAfterComponentsBeforeSelection = "Note your Country choice will be used as part of your shipping address to send products directly to you. If you do not see your country in the list, Gear is currently not available at this time in your country.";
const countryAfterComponentsAllowedSelection = "Note your Country choice will be used as part of your shipping address to send products directly to you."
const countryAfterComponentsLimitedSelection = "Gear is currently available in your country but it is opening in stages. Unless you have received notification, you may not be able to access Gear at this time in your country.";

const disclaimer = "By clicking on the next Button below, I acknowledge that I am a Company employee and I consent to the Company and its partner RemoteRetail to store and process the personal information submitted on this site to provide me the content requested. RemoteRetail need the contact information I provide so they may contact me with information related to my orders. Third party suppliers may also receive my information in order to ship products to me.</p><p>I may unsubscribe from these communications at any time. For more information on how I can unsubscribe, the privacy practices, and commitments to protecting and respecting my privacy, please review the <a href='{privacyPolicyUrl}' target='_blank'>Privacy Policy</a>.";

export default {
    title: "Welcome, {userName}!",
    description: {
        default: `We are proud to introduce Gear: <i>the</i> source for Company IT accessories. Gear allows you to order equipment shipped directly to your door without third party procurement systems or subsequent approvals.`,
        virtualworker: `We are proud to introduce Gear: <i>the</i> source for Company IT accessories that you need to work effectively from home. Gear allows you to order equipment shipped directly to your door without third party procurement systems or subsequent approvals.`
    },
    text: {
        default: "To start, we will ask you a series of questions.",
        virtualworker: "To start, we will ask you a series of questions to help us understand your work-from-home workspace to help shape the future direction of this program."
    },
    afterComponents: {
        beforeSelection: `${countryAfterComponentsBeforeSelection}`,
        allowedSelection: `${countryAfterComponentsAllowedSelection}`,
        limitedSelection: `${countryAfterComponentsLimitedSelection}`,
        autoLoginBeforeSelection: `${countryAfterComponentsBeforeSelection}<br/><br/>${disclaimer}`,
        autoLoginAllowedSelection: `${countryAfterComponentsAllowedSelection}<br/><br/>${disclaimer}`,
        autoLoginLimitedSelection: `${countryAfterComponentsLimitedSelection}<br/><br/>${disclaimer}`
    },
    buttons: {
        back: "Back",
        next: "Next",
        submit: "Submit"
    },
    entityinfo: {
        title: "Entity Information",
        description: "To help us properly route your order, please provide us the following information:",
    },
    equipmentsurvey: {
        title: "Equipment Survey",
        description: "The next page will ask you about the type of equipment you need most to help the Company to better understand the priorities.",
        info: "Please note that the equipment shown is for information gathering purposes only and does not imply that the Company will provide this equipment in the future."
    },
    workplace: {
        title: "Tell us more about your home office?",
        description: "To help us better understand your workspace, please select one of the choices below",
    },
    priority: {
        title: "Equipment Priorities",
        description: "Please select your top two choices of equipment you need most"
    },
    "additional-questions": {
        title: "Additional Questions"
    },
    "equipment-assessment": {
        title: "Equipment Assessment",
        description: "The following set of questions will <b>determine what equipment will be available for you to order from within Gear based on your answers.</b>"
    },
    headset: {
        title: "Current Equipment"
    },
    "office-worker": {
        title: "Office"
    },
    "keyboard-mouse": {
        title: "Keyboard and Mouse"
    },
    webcam: {
        title: "Webcam"
    },
    monitor: {
        title: "Monitor"
    },
    usbhub: {
        title: "USB Hub"
    },
    "equipment-list": {
        title: "Equipment",
        init: "initEquipmentList",
        description: {
            loading: "<span class=\"animate-spin icon-spinner\"></span>",
            error: "Bad Request. An error occurred.",
            allowed: "Based on your answers, you will be eligible for the following items:{items}",
            empty: "Based on your answers, you will currently not be eligible for any items in Gear."
        },
        text: "<ul class=\"text-left\" style=\"width: 300px; margin: 2rem auto 0\">{list}</ul>",
        afterComponents: "<small>If you need to make any corrections please hit the back button to change your answers.</small>"
    },
    saved: "Saved"
}