const RESKINNING_STYLE_KEY = "reskinning_style";
const RESKINNING_STYLE = process.env.VUE_APP_RESKINNING_STYLE;
const RESKINNING_LIFETIME = 3600 * 12;

export default {
    data() {

        return {
            reskinning_style: null
        }
    },
    methods: {
        reskinning_init() {

            const style = new URL(window.location.href).searchParams.get("style");

            this.reskinning_style = style ? style : this.$storage.get(RESKINNING_STYLE_KEY, RESKINNING_STYLE);

            this.$storage.set(RESKINNING_STYLE_KEY, this.reskinning_style, RESKINNING_LIFETIME);
        }
    }
}