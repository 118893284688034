import ViewsRouter from '@/views/ViewsRouter'

import ViewsProducts from "@/views/products/ViewsProducts";
import ViewsProductsProduct from "@/views/products/ViewsProductsProduct";
import ViewsProductsProxy from "@/views/products/ViewsProductsProxy";

import {home} from "@/router/breadcrumbs";
import {PERMISSIONS} from "@/constants";

export default {
    routes: [{
        path: "/products/:grouping",
        name: "products-parent",
        component: ViewsRouter,
        children: [{
            path: ":grouping_id?",
            name: "products",
            component: ViewsProducts,
            meta: {
                requireAuth: true,
                breadcrumbs: [home],
                permissions: [PERMISSIONS.EMPLOYEE]
            }
        }, {
            path: "proxy/:grouping_id",
            name: "products.proxy",
            component: ViewsProductsProxy,
            meta: {
                requireAuth: true,
                permissions: [PERMISSIONS.EMPLOYEE]
            }
        }, {
            path: ":grouping_id/product/:sku",
            name: "products.product",
            component: ViewsProductsProduct,
            meta: {
                requireAuth: true,
                breadcrumbs: [home],
                permissions: [PERMISSIONS.EMPLOYEE]
            }
        }]
    }]
}
