import pages from "./pages.mjs";
import components from "./components.mjs";

export const types = {
    dropdown: "dropdown",
    collection: "collection",
    textbox: "textbox",
    textarea: "textarea"
};

export default {
    options: {
        page: {
            text: ["title", "description", "text", "info", "afterComponents"]
        }
    },
    types,
    pages,
    components,
}