export default {
    country: {
        label: "Country of Residence",
        errors: {
            required: "A selection is required"
        }
    },
    entity: {
        label: "Entity",
        errors: {
            required: "A selection is required"
        }
    },
    location: {
        label: "Office Location",
        errors: {
            required: "A selection is required"
        }
    },
    workplace: {
        values: {
            "Dedicated Office": {
                title: "Dedicated Office",
                description: "Home office with a permanent location dedicated as an office"
            },
            "Semi-Dedicated": {
                title: "Semi-Dedicated",
                description: "Temporary location to accomodate the needs of a home working environment"
            },
            "Floating": {
                title: "Floating",
                description: "No dedicated space for home office but is shared with other locations such as a dining room or living room"
            }
        },
        errors: {
            required: "A selection is required"
        }
    },
    priority: {
        label: "Highest Priority",
        values: {
            "Monitor": {
                title: "Monitor"
            },
            "Docking Station": {
                title: "Docking Station"
            },
            "Keyboard/Mouse": {
                title: "Keyboard/Mouse"
            },
            "Web Cam": {
                title: "Web Cam"
            },
            "Headset": {
                title: "Headset"
            },
            "Office Chair": {
                title: "Office Chair"
            },
            "Desk": {
                title: "Desk"
            },
            "Adapters": {
                title: "Adapters"
            },
            "Uninterruptible Power Supply (UPS)": {
                title: "Uninterruptible Power Supply (UPS)"
            },
            "Something Else": {
                title: "Something Else"
            },
            "Nothing Needed": {
                title: "Nothing Needed"
            }
        },
        errors: {
            requiredUnless: "A selection is required",
            requiredIf: "A selection is required",
            required: "A selection is required"
        }
    },
    priorityDetails: {
        placeholder: "Provide details"
    },
    secondaryPriority: {
        label: "Second Highest Priority",
        values: {
            "Monitor": {
                title: "Monitor"
            },
            "Second Monitor": {
                title: "Second Monitor"
            },
            "Docking Station": {
                title: "Docking Station"
            },
            "Keyboard/Mouse": {
                title: "Keyboard/Mouse"
            },
            "Web Cam": {
                title: "Web Cam"
            },
            "Headset": {
                title: "Headset"
            },
            "Office Chair": {
                title: "Office Chair"
            },
            "Desk": {
                title: "Desk"
            },
            "Adapters": {
                title: "Adapters"
            },
            "Uninterruptible Power Supply (UPS)": {
                title: "Uninterruptible Power Supply (UPS)"
            },
            "Something Else": {
                title: "Something Else"
            },
            "Nothing Needed": {
                title: "Nothing Needed"
            }
        },
        errors: {
            requiredUnless: "A selection is required",
            requiredIf: "A selection is required",
            required: "A selection is required"
        }
    },
    secondaryPriorityDetails: {
        placeholder: "Provide details"
    },
    improveWorkspace: {
        label: "How would you improve your workspace?",
        placeholder: "Optional",
    },
    improveRemoteWorking: {
        label: "Outside of equipment, what would help you be more effective at remote working?",
        placeholder: "Optional",
    },
    receivedEquipmentHeadset: {
        label: "Have you received a wireless headset or speakerphone from the Company within the last 24 months?",
        values: {
            Yes: {
                name: "Yes"
            },
            No: {
                name: "No"
            }
        }
    },
    issuesHeadset: {
        label: "Do you have issues with it?",
        values: {
            Yes: {
                name: "Yes"
            },
            No: {
                name: "No"
            }
        }
    },
    issuesDescriptionHeadset: {
        placeholder: "Describe your issues"
    },
    isOfficeWorker: {
        label: "Do you work from the office full-time?",
        values: {
            Yes: {
                name: "Yes"
            },
            No: {
                name: "No"
            }
        }
    },
    receivedKeyboardMouse: {
        label: "Have you received a keyboard and mouse from the Company within the last 12 months?",
        values: {
            Yes: {
                name: "Yes"
            },
            No: {
                name: "No"
            }
        }
    },
    issuesKeyboardMouse: {
        label: "Do you have issues with it?",
        values: {
            Yes: {
                name: "Yes"
            },
            No: {
                name: "No"
            }
        }
    },
    issuesDescriptionKeyboardMouse: {
        placeholder: "Describe your issues"
    },
    needWebcam: {
        label: "Do you need an external webcam for work (not the one built into your laptop?",
        values: {
            Yes: {
                name: "Yes"
            },
            No: {
                name: "No"
            }
        }
    },
    needWebcamExternal: {
        label: "Do you need an external USB webcam (not the one built into your laptop)?",
        values: {
            Yes: {
                name: "Yes"
            },
            No: {
                name: "No"
            }
        }
    },
    needMonitor: {
        label: "Do you need a monitor for work?",
        values: {
            Yes: {
                name: "Yes"
            },
            No: {
                name: "No"
            }
        }
    },
    receivedMonitor: {
        label: "Have you received a monitor from the Company within the last 4 years?",
        values: {
            Yes: {
                name: "Yes"
            },
            No: {
                name: "No"
            }
        }
    },
    issuesMonitor: {
        label: "Do you have issues with it?",
        values: {
            Yes: {
                name: "Yes"
            },
            No: {
                name: "No"
            }
        }
    },
    issuesDescriptionMonitor: {
        placeholder: "Describe your issues"
    },
    needUsbhub: {
        label: "Do you need an external USB hub for work?",
        values: {
            Yes: {
                name: "Yes"
            },
            No: {
                name: "No"
            }
        }
    },
    issuesDescriptionUsbhub: {
        placeholder: "Describe your issues"
    }
}