<template>
    <span>
        <span class="icon-spinner animate-spin"></span>
        <span class="ml-1"><slot>{{t('loading')}}...</slot></span>
    </span>
</template>

<script>
    export default {
        name: "Loading",
        data() {

            return {
                t_path: "components.loading"
            }
        }
    }
</script>