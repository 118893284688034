export default {
    methods: {
        quiz_filter_countries(options) {

            const allowed = this.$store.getters["user/quiz/countries"];

            return options.reduce((result, option) => {

                const allowedCountry = allowed.find(item => option.value == item.iso_alpha3);

                if (allowedCountry) {

                    result.push({
                        ...option,
                        is_limited: allowedCountry.is_limited
                    });
                }

                return result;
            }, []);
        }
    }
}