<template>
    <div :class="class">

        <label v-if="label" :for="labelFor">{{label}}<span v-if="_required" class="label-required">*</span></label>

        <textarea :value="modelValue"
                  :id="labelFor"
                  :placeholder="placeholder"
                  :class="[{'is-invalid':isInvalid,'is-valid':isValid}, textareaClass]"
                  :disabled="disabled"
                  :tabindex="tabindex"
                  :maxlength="maxlength?maxlength:maxCharacters"
                  :rows="rows"
                  @input="$emit('update:modelValue', $event.target.value)"

                  ref="element"></textarea>

        <div v-if="showCharacterCounter&&modelValue" class="text-right small">
            <small>{{modelValue.length}}/{{maxCharacters}}</small>
        </div>

        <slot name="tip"></slot>

        <div v-for="error in _errors" :key="error" class="invalid-feedback">
            <span class="icon-attention-circled"></span>
            {{error}}
        </div>

    </div>
</template>

<script>

    import form from "@/mixins/form";
    import character_counter from "@/mixins/character_counter";

    export default {
        name: "FormTextarea",
        mixins: [form, character_counter],
        props: {
            modelValue: {},
            label: {
                type: String
            },
            type: {
                type: String,
                default: "text"
            },
            textareaClass: {
                type: String,
                default: "form-control"
            },
            class: {
                type: String,
                default: "form-group"
            },
            maxlength: {
                type: Number
            },
            rows: {
                type: Number
            }
        },
        emits: ['update:modelValue']
    }
</script>