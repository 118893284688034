import {mapMutations} from "vuex";

export default {
    methods: {
        ...mapMutations("breadcrumbs", {
            breadcrumbs_push: "push",
            breadcrumbs_pop: "pop",
            breadcrumbs_replace: "replace",
            breadcrumbs_empty: "empty"
        }),
        breadcrumbs_set(breadcrumbs, translate = true) {

            if (false === translate) {

                return this.$store.commit("breadcrumbs/set", breadcrumbs);
            }

            // Due to some reason this.$te is not always available
            const interval = setInterval(() => {

                if ("undefined" !== typeof this.$te) {

                    clearInterval(interval);
                    this.$store.commit("breadcrumbs/set", this.breadcrumbs_translate(breadcrumbs));
                }
            }, 5);
        },
        breadcrumbs_processRouteMeta(route) {

            route = route || this.$route;

            if (route.meta) {

                if ("undefined" === typeof route.meta.breadcrumbs) {

                    return this.breadcrumbs_empty();
                }

                this.breadcrumbs_set(route.meta.breadcrumbs);
            }
        },
        breadcrumbs_translate(breadcrumbs) {

            return breadcrumbs.map(item => ({
                ...item,
                text: this.$te(`breadcrumbs.${item.text}`) ? this.$t(`breadcrumbs.${item.text}`) : item.text
            }));
        }
    }
}