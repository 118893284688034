export default {
    workplace: {
        type: "collection",
        cell: {
            class: "col-6 col-md-4"
        },
        option: true,
        options: [{
            image: "assets/images/quiz/workplace/dedicated_office.png",
            value: "Dedicated Office",
            title: "title",
            description: "description"
        }, {
            image: "assets/images/quiz/workplace/semi_dedicated_office_space.png",
            value: "Semi-Dedicated",
            title: "title",
            description: "description"
        }, {
            image: "assets/images/quiz/workplace/floating_office.png",
            value: "Floating",
            title: "title",
            description: "description"
        }],
        validation: {
            required: true,
            messages: {
                required: "error.required"
            }
        },
        payload: {
            field: "workplace"
        }
    },

    priority: {
        type: "dropdown",
        wrapperCssClass: "col-12 col-md-6 offset-lg-2 col-lg-4",

        options: [{
            value: "Monitor",
            name: "title",
        }, {
            value: "Docking Station",
            name: "title",
        }, {
            value: "Keyboard/Mouse",
            name: "title",
        }, {
            value: "Web Cam",
            name: "title",
        }, {
            value: "Office Chair",
            name: "title",
            hide: {
                country: ["USA", "CAN", "IND", "VNM", "PHL"]
            }
        }, {
            value: "Desk",
            name: "title",
            hide: {
                country: ["USA", "CAN", "IND", "VNM", "PHL"]
            }
        }, {
            value: "Adapters",
            name: "title"
        }, {
            value: "Uninterruptible Power Supply (UPS)",
            name: "title",
            display: {
                country: ["IND"]
            }
        }, {
            value: "Something Else",
            name: "title"
        }, {
            value: "Nothing Needed",
            name: "title"
        }],

        validation: {
            required: true,
            messages: {
                requiredUnless: "error.requiredUnless",
                requiredIf: "error.requiredIf",
                required: "error.required"
            }
        },
        payload: {
            field: "priority",
            type: "array"
        }
    },
    priorityDetails: {
        type: "textbox",
        wrapperCssClass: "col-12 col-md-6 offset-lg-2 col-lg-4",
        placeholder: "placeholder",
        maxCharacters: 256,
        payload: {
            field: "priorityDetails"
        },
        validation: {
            requiredIf: {
                priority: ["Something Else"]
            }
        },
        clear: {
            priority: [
                "Monitor",
                "Docking Station",
                "Keyboard/Mouse",
                "Web Cam",
                "Headset",
                "Office Chair",
                "Desk",
                "Adapters",
                "Uninterruptible Power Supply (UPS)",
                "Nothing Needed"
            ]
        }
    },

    secondaryPriority: {
        type: "dropdown",
        wrapperCssClass: "col-12 col-md-6 col-lg-4",
        disable: {
            priority: [null, ""]
        },
        options: [{
            value: "Monitor",
            name: "title",
            hide: {
                priority: ["Monitor"]
            }
        }, {
            value: "Second Monitor",
            name: "title",
            display: {
                priority: ["Monitor"]
            }
        }, {
            value: "Docking Station",
            name: "title",
            hide: {
                priority: ["Docking Station"]
            }
        }, {
            value: "Keyboard/Mouse",
            name: "title",
            hide: {
                priority: ["Keyboard/Mouse"]
            }
        }, {
            value: "Web Cam",
            name: "title",
            hide: {
                priority: ["Web Cam"]
            }
        }, {
            value: "Office Chair",
            name: "title",
            hide: {
                priority: ["Office Chair"],
                country: ["USA", "CAN", "IND", "VNM", "PHL"]
            }
        }, {
            value: "Desk",
            name: "title",
            hide: {
                priority: ["Desk"],
                country: ["USA", "CAN", "IND", "VNM", "PHL"]
            }
        }, {
            image: "assets/images/quiz/needs/DXC_computer_adapter.png",
            value: "Adapters",
            name: "title",
            hide: {
                priority: ["Adapters"]
            }
        }, {
            value: "Uninterruptible Power Supply (UPS)",
            name: "title",
            display: [{
                country: ["IND"]
            }, {
                priority: [
                    "Monitor",
                    "Docking Station",
                    "Keyboard/Mouse",
                    "Web Cam",
                    "Office Chair",
                    "Desk",
                    "Adapters",
                    "Something Else",
                    "Nothing Needed"
                ]
            }]
        }, {
            value: "Something Else",
            name: "title"
        }, {
            value: "Nothing Needed",
            name: "title"
        }],
        validation: {
            required: true,
            messages: {
                requiredUnless: "error.requiredUnless",
                requiredIf: "error.requiredIf",
                required: "error.required"
            }
        },
        payload: {
            field: "secondaryPriority",
            type: "array"
        }
    },
    secondaryPriorityDetails: {
        type: "textbox",
        wrapperCssClass: {
            "col-12 col-md-6 col-lg-4": true,
            "offset-lg-0": {
                priority: ["Something Else"]
            },
            "offset-lg-6 offset-md-6": {
                priority: ["Monitor", "Docking Station", "Keyboard/Mouse", "Web Cam", "Headset", "Office Chair", "Desk", "Adapters", "Nothing Needed"]
            }
        },
        placeholder: "placeholder",
        payload: {
            field: "secondaryPriorityDetails"
        },
        maxCharacters: 256,
        validation: {
            requiredIf: {
                secondaryPriority: ["Something Else"]
            }
        },
        clear: {
            secondaryPriority: [
                "Monitor",
                "Second Monitor",
                "Docking Station",
                "Keyboard/Mouse",
                "Web Cam",
                "Headset",
                "Office Chair",
                "Desk",
                "Adapters",
                "Nothing Needed"
            ]
        }
    },

    improveWorkspace: {
        type: "textarea",
        wrapperCssClass: "offset-md-3 col-md-6",
        placeholder: "placeholder",
        maxCharacters: 2000,
        payload: {
            field: "improveWorkspace"
        }
    },
    improveRemoteWorking: {
        type: "textarea",
        wrapperCssClass: "offset-md-3 col-md-6",
        placeholder: "placeholder",
        maxCharacters: 2000,
        payload: {
            field: "improveRemoteWorking"
        }
    }
}