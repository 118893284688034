const id = 'toasts';

export default {
    install(app, options = {}) {

        let element = document.createElement('div');

        element.setAttribute('id', id);
        element.className = 'toasts';

        document.body.appendChild(element);

        const toast = {
            id: id,
            success: function (message, options) {
                options = options || {};
                options.type = 'toasts-item-success';

                return this.create(message, options);
            },
            warning: function (message, options) {
                options = options || {};
                options.type = 'toasts-item-warning';

                return this.create(message, options);
            },
            error: function (message, options) {
                options = options || {};
                options.type = 'toasts-item-error';

                return this.create(message, options);
            },
            create: function (message, options) {

                options = options || {};
                const timeout = options.timeout ? options.timeout : 3000;

                var element = document.getElementById(this.id),
                    id = ("id_" + new Date().getTime().toString() + Math.random().toString()).replace('.', '_'),
                    elementToast = document.createElement('div'),
                    elementButtonClose = document.createElement('button'),
                    timerRemove;

                elementToast.className = 'toasts-item';
                elementToast.setAttribute('id', id);
                elementToast.innerText = message;

                if (options.type) {

                    elementToast.classList.add(options.type);
                }

                elementButtonClose.setAttribute('type', 'button');
                elementButtonClose.className = 'toasts-item-close';
                elementButtonClose.innerHTML = '<span class="icon-cancel"></span>';

                var remove = function () {

                    var toastElement = document.getElementById(id);

                    if (toastElement) {

                        toastElement.parentNode.removeChild(toastElement);
                    }
                };

                var removeHandler = function () {
                    clearTimeout(timerRemove);
                    remove();
                };
                elementButtonClose.addEventListener('click', removeHandler);

                elementToast.appendChild(elementButtonClose);

                element.appendChild(elementToast);

                setTimeout(function () {

                    document.getElementById(id).setAttribute('data-control-active', true);
                }, 100);


                timerRemove = setTimeout(remove, timeout);

                return true;
            }
        };

        app.provide('$toast', toast);
        app.config.globalProperties.$toast = toast;
    }
}