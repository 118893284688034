import {getStorage} from "@/api/storage";

const STORAGE_KEY = "cart";

export default {
    namespaced: true,
    state: {
        id: "sku",
        items: []
    },
    getters: {
        id: state => state.id,
        items: state => state.items,
        exists: state => (item) => -1 !== state
            .items
            .findIndex(i => i.item[state.id] == item[state.id])
    },
    mutations: {
        setItems: (state, items) => state.items = items,
        addItem: (state, item) => {

            const index = state.items.findIndex(i => i.item[state.id] == item[state.id]);

            if (-1 === index) {

                // Insert new item to the list
                state.items.push({
                    item,
                    amount: 1,
                });
            } else {

                state.items[index].amount++;
            }
        },
        setItemAmount: (state, options) => {

            const index = state.items.findIndex(i => i.item[state.id] == options.item[state.id]);

            state.items[index].amount = options.amount;

        },
        removeItem: (state, item) => {

            // Get item index
            const index = state.items.findIndex(i => i.item[state.id] == item[state.id]);

            // Remove item from the list
            state.items.splice(index, 1);
        },
        updateStorage: (state) => getStorage().set(STORAGE_KEY, state.items)
    },
    actions: {
        fetch(context, items) {

            items = items || getStorage().get(STORAGE_KEY, []);

            context.commit("setItems", items);
        },
        setItems: (context, items) => {

            context.commit("setItems", items);
            context.commit("updateStorage");

        },
        addItem(context, item) {

            context.commit("addItem", item);
            context.commit("updateStorage");
        },
        setItemAmount(context, options) {

            context.commit("setItemAmount", options);
            context.commit("updateStorage");
        },
        removeItem(context, item) {

            context.commit("removeItem", item);
            context.commit("updateStorage");
        },
        clear(context) {

            context.commit("setItems", []);
            context.commit("updateStorage");
        }
    },
    modules: {}
}