import ViewsAcknowledgement from "@/views/acknowledgement/ViewsAcknowledgement";

export default {
    routes: [{
        path: "/acknowledgement",
        name: "acknowledgement",
        component: ViewsAcknowledgement,
        meta: {
            requireAuth: true,
            acknowledged: true
        }
    }]
}
