import {platform} from "@/api/platform";

export default {
    install(app, options = {}) {

        const activity = {
            log(type, data) {

                const request = {
                    url: window.location.href,
                    timestamp: Math.floor((new Date()).getTime() / 1000),
                    type,
                    data,
                };

                platform.post("/api/v2/portal/track", request)
            }
        };

        app.provide('$activity', activity);
        app.config.globalProperties.$activity = activity;
    }
}