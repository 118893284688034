<template>
    <span v-if="description" class="collection-item-description">{{description}}</span>
</template>

<script>
    export default {
        name: "CollectionItemDescription",
        props: {
            description: String
        }
    }
</script>