export default {
    model: {
        email: {
            label: "Email",
            placeholder: "Delegate email"
        },
        start_on: {
            label: "Start Date",
            placeholder: "Date to start delegation"
        },
        end_on: {
            label: "End Date",
            placeholder: "Optional"
        },
        accept: {
            tip: "By delegating your approving responsibility, you certify that you are taking accountability and responsibility for any orders approved by your delegate.",
            label: "I accept"
        }
    },
    buttons: {
        submit: {
            cta: "Confirm",
            processing: "Saving"
        },
        close: {
            cta: "Cancel"
        }
    },
    saved: "Saved"
}