export default {
    methods: {
        view_setTitle(title) {

            document.title = title || this.t('title');
        }
    },
    mounted() {

        this.view_setTitle();
    }
}