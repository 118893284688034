import ModalConfirm from "@/modal/ModalConfirm";
import ModalMessage from "@/modal/ModalMessage";

export default {
    install(app, options = {}) {

        let components = {};

        const modal = {
            confirm(options) {

                let props = {};

                if ("string" === typeof options) {

                    props.message = options;
                } else {

                    props = options;
                }

                return this.show({
                    component: ModalConfirm,
                    props
                });
            },
            message(options) {

                let props = {};
                if ("string" === typeof options) {

                    props.message = options;
                } else {

                    props = options;
                }

                return this.show({
                    component: ModalMessage,
                    props
                });
            },
            show(opts) {

                if (!components[opts.component.name]) {

                    app.component(opts.component.name, opts.component);
                }

                return new Promise(function (resolve, reject) {

                    let index = options.store.getters['modals/items'].length,
                        modal = {
                            id: 'modal_' + Math.random(),
                            resolve,
                            reject,
                            _ok(result) {

                                resolve(result);

                                options.store.dispatch('modals/splice', {index, count: 1});
                            },
                            ...opts
                        };

                    options.store.dispatch('modals/push', modal);
                });
            }
        };

        app.provide('$modal', modal);
        app.config.globalProperties.$modal = modal;
    }
}