import {create} from "./_api";

const api = create();

export const platform = api;

export default {
    api,
    install(app, options = {}) {

        api.defaults.baseURL = options.baseURL;
        api.defaults.params = options.params;

        app.provide("$platform", api);
        app.config.globalProperties.$platform = api;
    }
}