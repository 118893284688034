import {platform} from "@/api/platform";
import {retryRequest} from "@/helpers/request";

export default {
    namespaced: true,
    state: {
        containers: {
            beforeAddress: "beforeAddress",
            afterAddress: "afterAddress",
        },
        config: []
    },
    getters: {
        config: state => JSON.parse(JSON.stringify(state.config)),
        configBeforeAddress: state => state.config.filter(item => state.containers.beforeAddress === item.container),
        configAfterAddress: state => state.config.filter(item => state.containers.afterAddress === item.container),
        configOrder: state => state.config.filter(item => !item.checkout_only),
        configOrderEditable: state => state.config.filter(item => !item.checkout_only && item.editable),
        configOrderNotEditable: state => state.config.filter(item => !item.checkout_only && !item.editable),
    },
    mutations: {
        setConfig: (state, config) => state.config = config,
    },
    actions: {
        fetch(context) {

            const userId = this.getters["user/id"];

            return retryRequest(() => platform.get(`/api/v2/user/${userId}/checkout-config`))
                .then(response => context.commit("setConfig", response.data.results));
        },
        clear(context) {

            context.commit("setConfig", []);
        }
    }
}