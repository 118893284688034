import ViewsAuthLogin from "@/views/auth/ViewsAuthLogin";
import ViewsAuthLogout from "@/views/auth/ViewsAuthLogout";
import ViewsAuthConfirm from "@/views/auth/ViewsAuthConfirm";
import ViewsAuthLoginSso from "@/views/auth/ViewsAuthLoginSso";

export default {
    routes: [{
        path: "/login",
        name: "auth.login",
        component: ViewsAuthLogin,
        meta: {
            requireAuth: false,
            allowAutoLogin: false,
            breadcrumbs: []
        }
    }, {
        path: "/logout",
        name: "auth.logout",
        component: ViewsAuthLogout,
        meta: {
            requireAuth: true,
            breadcrumbs: []
        }
    }, {
        path: "/confirm-email",
        name: "auth.confirm",
        component: ViewsAuthConfirm,
        meta: {
            requireAuth: false,
            breadcrumbs: []
        }
    }, {
        path: "/login/sso",
        name: "auth.sso",
        component: ViewsAuthLoginSso,
        meta: {
            requireAuth: false,
            breadcrumbs: []
        }
    }]
}