<template>
    <div class="products-product-thumbnails">
        <carousel class="story-carousel story-carousel--colors" ref="carousel">
            <carousel-slide class="story-carousel__slide" v-for="(image, index) in images" :key="index">
                <router-link v-if="route" :to="route"><img :src="image.url" :alt="index"
                                                           class="products-product-thumbnails-image"></router-link>
                <img v-else :src="image.url" :alt="index" class="products-product-thumbnails-image">
            </carousel-slide>
        </carousel>
    </div>
</template>

<script>

    import Carousel from "@/components/carousel/Carousel";
    import CarouselSlide from "@/components/carousel/CarouselSlide";

    export default {
        name: "ProductsProductThumbnails",
        components: {
            CarouselSlide,
            Carousel
        },
        props: {
            value: {
                type: Array,
                required: true
            },
            arrows: {
                type: Boolean,
                default: false
            },
            route: Object,
            size: {
                type: String,
                default: "medium"
            }
        },
        data() {

            return {
                current: 0
            }
        },
        computed: {
            images() {

                return this.value.map(image => {

                    return {
                        url: image[this.size]
                    }
                });
            }
        },
        methods: {
            next() {


                if ("undefined" === this.value[this.current + 1]) {

                    this.current = 0;
                } else {

                    this.current++;
                }

            },
            prev() {

                if ("undefined" === this.value[this.current]) {

                    this.current = this.value.length - 1;
                } else {
                    this.current--;
                }
            }
        }
    }
</script>