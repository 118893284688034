<template>
    <div class="collection">
        <slot></slot>

        <div class="collection-error" :class="[errorClass]" v-for="error in _errors" :key="error">
            <span class="icon-attention-circled"></span>
            {{error}}
        </div>
    </div>
</template>

<script>

    import form from "@/mixins/form";

    export default {
        name: "Collection",
        mixins: [form],
        props: {
            modelValue: {
                type: Array,
                required: true
            },
            option: Boolean,
            selectionLimit: {
                type: Number
            },
            errorClass: String
        },
        emits: ['update:modelValue'],
        data() {

            return {
                options: []
            }
        },
        methods: {
            add(option, value) {

                const index = this.options.findIndex(o => o.modelValue == value);

                if (-1 === index) {

                    this.options.push(option);
                }

                return new Promise(resolve => {

                    const index = this.modelValue.indexOf(value);

                    resolve(-1 !== index);
                });
            },
            toggle(value) {

                return new Promise(resolve => {

                    const index = this.modelValue.indexOf(value);

                    if (this.option) {

                        this.modelValue.splice(0);

                        this.options.forEach(option => option.selected = false);

                        if (-1 === index) {

                            this.modelValue.push(value);
                            return resolve(true);
                        }

                        return resolve(false);
                    }

                    if (-1 === index) {

                        if (!this.selectionLimit || this.selectionLimit >= this.modelValue.length + 1) {

                            this.modelValue.push(value);
                            resolve(true);
                        }
                    } else {

                        this.modelValue.splice(index, 1);
                        resolve(false);
                    }
                });
            }
        },
        watch: {
            modelValue: {
                deep: true,
                handler(newValue) {

                    if (0 === newValue.length) {

                        this.options.forEach(option => option.selected = false);
                    }

                }
            }
        }
    }
</script>