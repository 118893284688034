<template>
    <div class="panel">
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: "Panel"
    }
</script>