import ViewsHelpdesk from "@/views/helpdesk/ViewsHelpdesk";
import {PERMISSIONS} from "@/constants";


export default {
    routes: [{
        path: "/helpdesk",
        name: "helpdesk",
        component: ViewsHelpdesk,
        meta: {
            requireAuth: true,
            permissions: [PERMISSIONS.HELPDESK]
        }
    }]
}