export default {
    namespaced: true,
    state: {
        items: []
    },
    mutations: {
        push(state, item) {

            state.items.push(item);
        },
        splice(state, data) {

            state.items.splice(data.index, data.count);
        },
        reject(state, index) {

            state.items[index].reject();
        }
    },
    actions: {
        push(context, item) {

            context.commit('push', item);
        },
        splice(context, data) {

            context.commit('splice', data);
        },
        reject(context, index) {

            context.commit('reject', index);
        }
    },
    getters: {
        items(state) {

            return state.items.slice(0);
        }
    }
}