<template>
    <div class="tree-view" v-if="0 < items.length">
        <div v-for="item in categories" :key="item.id" class="tree-view-item">

            <div class="d-flex">
                <div class="flex-grow-1">
                    <button type="button" class="btn btn-sm btn-link tree-view-item-button"
                            :class="{'tree-view-item-button-active':value==item.id}"
                            @click="$emit('select', item.id)">{{item.name}}
                    </button>
                </div>
                <div v-if="item.children && 0 < item.children.length">
                    <button type="button" class="btn btn-sm"
                            @click="display.children[item.id]=!display.children[item.id]">
                        <span :class="{'icon-plus':!display.children[item.id],'icon-minus':display.children[item.id]}"></span>
                    </button>
                </div>
            </div>

            <div v-if="display.children[item.id] && item.children && 0 < item.children.length" class="pl-3">
                <tree-view :items="item.children"
                           @expand="expand"
                           @select="$emit('select', $event)"></tree-view>
            </div>
        </div>

    </div>
</template>

<script>
    import {getTranslationValue} from "@/i18n/utils/lang_overrides";

    export default {
        name: "TreeView",
        props: {
            value: {},
            items: {
                type: Array,
                required: true
            }
        },
        data() {

            return {

                display: {
                    children: {}
                }

            }
        },
        computed:{
            categories() {

                return this.items.map(item => ({
                    ...item,
                    name: getTranslationValue(item, "name", this.$i18n)
                }))
            }
        },
        methods: {
            toggleChildren(id) {

                this.display.children[id] = !this.display.children[id]
            },
            expand(id) {

                this.display.children[id] = true;
            }
        },
        mounted() {

            this.items.forEach(item => {

                if (item.id == this.value && item.parent_id) {

                    this.$emit("expand", item.parent_id);
                }
            });
        }
    }
</script>