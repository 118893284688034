<template>
    <div :class="class">

        <label v-if="label" :for="labelFor">{{label}}<span v-if="_required" class="label-required">*</span></label>

        <select :value="modelValue"
                :id="labelFor"
                :disabled="disabled"
                :tabindex="tabindex"
                :class="[{'is-invalid':isInvalid,'is-valid':isValid}, selectClass]"

                @change="change($event.target.value)"

                ref="element">
            <option disabled selected value style="display: none"></option>
            <option v-for="option in options"
                    :value="option[optionValue]"
                    :key="option[optionValue]+option[optionName]">
                {{option[optionName]}}
            </option>
        </select>

        <slot name="tip"></slot>

        <div v-for="error in _errors" :key="error" class="invalid-feedback">
            <span class="icon-attention-circled"></span>
            {{error}}
        </div>

    </div>
</template>

<script>

    import form from "@/mixins/form";

    export default {
        name: "FormSelect",
        mixins: [form],
        props: {
            modelValue: {},
            label: {
                type: String
            },
            class: {
                type: String,
                default: "form-group"
            },
            selectClass: {
                type: String,
                default: "form-control form-control-select"
            },
            options: {
                type: Array,
                required: true
            },
            optionValue: {
                type: String,
                default: "value"
            },
            optionName: {
                type: String,
                default: "name"
            }
        },
        emits: ['update:modelValue'],
        methods: {
            change(value) {

                this.$emit("update:modelValue", value == parseInt(value) ? parseInt(value) : value);
            }
        }
    }
</script>