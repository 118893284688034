export default {
    400: {
        title: "Bad Request",
        header: "@:views.errors.400.title",
        html: "Bad Request. Please try again later."
    },
    401: {
        title: "We've encountered an error on login",
        header: "@:views.errors.401.title",
        html: "Please contact <a href='{email}' target='_blank'>support</a>."
    },
    404: {
        title: "Page Not Found",
        header: "@:views.errors.404.title"
    },
    403: {
        title: "Eligibility Requirements",
        header: "Looks like you’re a bit early…",

        contact: "<p class='text-left'>Thank you for your interest in Gear. It appears that you have reached the site before being enabled and invited directly. Gear is being rolled out in stages country by country and by groups of users once regulatory requirements are worked through and as inventory is in place. When you are activated, you will receive an email containing a personal invite with instructions.</p><p class='text-left'>Click on <a href='#' role='button' data-widget-trigger='{widgetOptions}'>Gear Support</a> for questions or issues with your order or the Gear site.</p>",

        subject: "Gear - Eligibility",

        html: "<p class='text-left'> @:views.errors.403.eligibility @:views.errors.403.contact </p>",
        error: {
            eligibility: {
                header: "@:views.errors.403.header",
                subject: "Gear - Eligibility",
                html: " @:views.errors.403.eligibility @:views.errors.403.contact",
            },
            "eligibility-location": {
                header: "Gear is currently unavailable",
                subject: "Gear is currently unavailable",
                html: "We are very sorry for the inconvenience. At this time, Gear order placement has been put on hold in your region. We will communicate when and if it becomes available.",
            },
            country: {
                header: "@:views.errors.403.header",
                html: " @:views.errors.403.eligibility @:views.errors.403.contact",
            },
            "no-permissions": {
                header: "No Access",
                subject: "No Access",
                html: "You do not have permissions to open this page.",
            }
        }
    },
    500: {
        title: "Unexpected error",
        header: "@:views.errors.500.title",
        message: "An error occurred. Please try again later."
    },
    code: {
        title: "Unexpected error",
        message: "@:views.errors.code.title",
        error: "Error {code}."
    }
}