/**
 * Converts payload value to array
 * @param value
 * @returns {*[]|*}
 */
const getArrayValue = (value) => {

    if (false === Array.isArray(value)) {

        return [value];
    }

    return value;
};

export default {
    methods: {
        /**
         * Handle quiz model to collect payload for sending to API
         * @param model
         */
        quiz_payload_get(quiz, model, components, valueIfComponentNotFound) {

            const payload = {};

            // Go through model and collect each value
            Object.keys(model).forEach(name => {

                if ("undefined" === typeof components || -1 !== components.indexOf(name)) {

                    const component = quiz.components[name];

                    // Check we have component
                    if (component) {

                        switch (component.payload.type) {
                            case "array":
                                payload[component.payload.field] = getArrayValue(model[name]);
                                break;

                            default:
                                // Add model value to payload
                                payload[component.payload.field] = model[name];
                        }
                    }
                } else if ("undefined" !== typeof components && -1 === components.indexOf(name) && "undefined" !== typeof valueIfComponentNotFound) {

                    const component = quiz.components[name];

                    // Check we have component
                    if (component) {

                        switch (component.payload.type) {
                            case "array":
                                payload[component.payload.field] = []
                                break;

                            default:
                                // Add model value to payload
                                payload[component.payload.field] = valueIfComponentNotFound;
                        }
                    }

                }
            });

            return payload;
        }
    }
}