import {v4 as uuidv4} from "uuid";

// Processing object is used for preveting the same requests at the same time
const processing = {
    expiration: 100,
    value: uuidv4(),
    keys: {}
};

export function retryRequest(process, delays, shouldRetry, key) {

    delays = "undefined" === typeof delays ? [1000, 2000] : delays;

    return new Promise((resolve, reject) => {

        // Check for we have check for processing requests
        if (key) {

            if ("undefined" === typeof processing.keys[key]) {
                // No requests, so we have to set mock by the key for makeing next requests understand that something is being already processed
                processing.keys[key] = processing.value;
            } else if (processing.value === processing.keys[key]) {

                // Wait for result of cached request
                let waitingInterval = setInterval(() => {

                    if (processing.value !== processing.keys[key]) {

                        resolve(processing.keys[key]);
                        clearInterval(waitingInterval);
                    }

                }, 5);

                return;
            }
        }

        // First attempt
        process()
            .then(result => {

                resolve(result);

                if (key) {

                    processing.keys[key] = result;
                    setTimeout(() => delete processing.keys[key], processing.expiration);
                }
            })
            .catch(error => {

                if ("undefined" === typeof delays[0]) {

                    return reject(error);
                }

                if ("function" === typeof shouldRetry) {

                    const shouldRetryResult = shouldRetry(error);

                    if ("boolean" === typeof shouldRetryResult && false === shouldRetryResult) {

                        return resolve(error);
                    }
                }

                // Second attempt
                setTimeout(() => process()
                    .then(result => {
                        resolve(result);

                        if (key) {

                            processing.keys[key] = result;
                            setTimeout(() => delete processing.keys[key], processing.expiration);
                        }
                    })
                    .catch(error => {

                        if ("undefined" === typeof delays[1]) {

                            return reject(error);
                        }

                        if ("function" === typeof shouldRetry) {

                            const shouldRetryResult = shouldRetry(error);

                            if ("boolean" === typeof shouldRetryResult && false === shouldRetryResult) {

                                return resolve(error);
                            }
                        }

                        // Third attempt
                        setTimeout(
                            () => process()
                                .then(result => {

                                    resolve(result)

                                    if (key) {

                                        processing.keys[key] = result;
                                        setTimeout(() => delete processing.keys[key], processing.expiration);
                                    }
                                })
                                .catch(error => reject(error)),
                            delays[1]
                        );

                    }), delays[0]);
            });
    });
}