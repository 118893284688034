const togglerClass = "show";

const handleElement = (el, target) => {

    el.dropdownTarget = target;

    if (el.dropdownTarget) {

        el.dropdownDropdownTargetHandler = function (event) {

            el.dropdownTarget.classList.remove(togglerClass);

            window.removeEventListener('click', el.dropdownDropdownTargetHandler);
        };

        el.dropdownDropdownHandler = function (event) {

            event.preventDefault();

            if (el.dropdownTarget.classList.contains(togglerClass)) {

                el.dropdownTarget.classList.remove(togglerClass);
            } else {

                el.dropdownTarget.classList.add(togglerClass);

                setTimeout(function () {

                    window.addEventListener('click', el.dropdownDropdownTargetHandler, true);
                }, 100);
            }
        };

        el.addEventListener('click', el.dropdownDropdownHandler);
    }
}

export default {
    mounted: function (el, binding) {

        if (binding.value) {

            handleElement(el, binding.value.variable[binding.value.key]);
        } else {

            Object.keys(binding.modifiers).forEach(ref => {

                if (binding.instance.$refs[ref]) {

                    handleElement(el,  binding.instance.$refs[ref]);
                }
            })
        }
    },
    unmounted: function (el) {

        el.removeEventListener('click', el.dropdownDropdownHandler);
        window.removeEventListener('click', el.dropdownDropdownTargetHandler);
    }
}