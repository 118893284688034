import {platform} from "@/api/platform";
import {getStorage} from "@/api/storage";

import equipment from "./quiz/equipment";
import profile from "./quiz/profile";
import {retryRequest} from "@/helpers/request";

const STORAGE_KEY = "quiz";

export default {
    namespaced: true,
    state: {
        countries: []
    },
    getters: {
        countries: state => state.countries,
    },
    mutations: {
        setModel: (state, model) => state.model = model,
        updateStorage: state => getStorage().set(STORAGE_KEY, state.model)
    },
    actions: {
        fetch(context) {

            if (context.state.countries && 0 < context.state.countries.length) {

                return context.state.countries
            }

            return retryRequest(() => platform.get("/api/v2/partner/countrieslist"))
                .then(response => context.state.countries = response.data.results);
        }
    },
    modules: {
        equipment,
        profile,
    }
}