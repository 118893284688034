const tokens = {
    '#': {pattern: /[0-9]/},
    'X': {pattern: /[0-9a-zA-Z]/},
    'S': {pattern: /[a-zA-Z]/},
    'A': {pattern: /[a-zA-Z]/, uppercase: true},
    'a': {pattern: /[a-zA-Z]/, lowercase: true},
    '!': {escape: true},
    '*': {repeat: true}
};

/* eslint quote-props: ["error", "consistent"] */
export default tokens;

export function getRegexFromMask(mask) {

    let regex = [], maskChar, token;

    for (let i = 0; i < mask.length; i++) {

        maskChar = mask[i];
        token = tokens[maskChar];

        if (token) {

            if (token.escape) {

                regex.push("\\");
            }

            if (token.repeat) {

                regex.push("+");
            }

            if (token.pattern) {

                regex.push(token.pattern.source);
            }
        } else {

            regex.push("\\" + maskChar);
        }
    }

    return new RegExp(["^", regex.join(""), "$"].join(""));
}
