<template>
    <div :class="[{'is-invalid':isInvalid,'is-valid':isValid}]">
        <div :class="class">

            <input type="checkbox" class="form-check-input"
                   :value="selectedValue"
                   :id="labelFor"
                   :disabled="disabled"
                   :checked="isChecked" @input="update"/>

            <label v-if="label" class="form-check-label" :for="labelFor" v-html="label"></label>

        </div>
    </div>
    <div v-for="error in _errors" :key="error" class="invalid-feedback">
        <span class="icon-attention-circled"></span>
        {{error}}
    </div>
</template>

<script>

    import form from "@/mixins/form";

    export default {
        name: "FormCheckbox",
        mixins: [form],
        props: {
            selectedValue: {
                default: true
            },
            modelValue: {},
            label: {
                type: String
            },
            class: {
                type: String,
                default: "form-check"
            },
        },
        methods: {
            update(event) {

                if (this.isArray) {
                    const index = this.modelValue.indexOf(this.selectedValue);

                    if (event.target.checked) {

                        if (index === this.modelValue.indexOf(this.selectedValue)) {

                            this.modelValue.push(this.selectedValue);
                        } else {

                            this.modelValue.splice(index, 1);
                        }
                    } else {

                        this.modelValue.splice(index, 1);
                    }

                } else {

                    if (event.target.checked) {

                        this.$emit("update:modelValue", this.selectedValue);
                    } else {

                        this.$emit("update:modelValue", null);
                    }
                }
            }
        },
        computed: {
            isArray() {

                return Array.isArray(this.modelValue);
            },
            isChecked() {

                return this.isArray
                    ? -1 !== this.modelValue.indexOf(this.selectedValue)
                    : this.modelValue == this.selectedValue;
            }
        }
    }
</script>