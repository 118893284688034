export default {
    header: "Confirm Address and Submit Order",
    message: "Is your shipping address listed below correct?",
    tip: "Click <b>YES</b> to confirm the shipping address and submit the order. Click <b>NO</b> to cancel the submission and choose another address.",
    info: "Once an order is placed, it cannot be changed. If the product you are ordering is backordered or has a later ship date and you are planning to move please take this into consideration when choosing the shipping address. Additionally, if there is an upcoming holiday or vacation and you will not be home to accept the product, we ask that you hold on placing any orders until you will be home to receive the products.",
    warning: {
        message: "Please double-check that your {fields} is correct since the shipping company may not be able to deliver your or order or contact you about your order. | Please double-check that your {fields} are correct since the shipping company may not be able to deliver your or order or contact you about your order.",
        name: "Name",
        street: "Street",
        phone: "Phone"
    },
    buttons: {
        yes: "Yes",
        no: "No"
    }
}