import {getStorage} from "@/api/storage";

const STORAGE_KEY = "quiz_profile";

export default {
    namespaced: true,
    state: {
        model: {}
    },
    getters: {
        model: state => state.model
    },
    mutations: {
        setModel: (state, model) => state.model = model,
        updateStorage: state => getStorage().set(STORAGE_KEY, state.model)
    },
    actions: {
        fetch(context, model) {

            model = model || getStorage().get(STORAGE_KEY, {});
            context.commit("setModel", model);
        },
        setModel(context, model) {

            context.commit("setModel", model);
            context.commit("updateStorage");
        },
        clear(context) {

            context.commit("setModel", {});
            context.commit("updateStorage");
        }
    }
}