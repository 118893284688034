import ViewsErrors400 from "@/views/errors/ViewsErrors400";
import ViewsErrors401 from "@/views/errors/ViewsErrors401";
import ViewsErrors404 from "@/views/errors/ViewsErrors404";
import ViewsErrors403 from "@/views/errors/ViewsErrors403";
import ViewsErrors500 from "@/views/errors/ViewsErrors500";
import ViewsErrorsCode from "@/views/errors/ViewsErrorsCode";

export default {
    routes: [{
        path: "/404",
        name: "errors.404",
        component: ViewsErrors404
    }, {
        path: "/401",
        name: "errors.401",
        component: ViewsErrors401
    }, {
        path: "/403/:error?",
        name: "errors.403",
        component: ViewsErrors403
    }, {
        path: "/400",
        name: "errors.400",
        component: ViewsErrors400
    }, {
        path: "/500",
        name: "errors.500",
        component: ViewsErrors500
    }, {
        path: "/error/:code",
        name: "errors.code",
        component: ViewsErrorsCode
    }]
}