<template>
    <div class="carousel">

        <div ref="vsWrapper" class="carousel__wrapper">
            <slot/>
        </div>

        <!-- @slot Slot for Arrows -->
        <slot v-if="!hideArrows&&total&&1!=total" name="arrows">
            <button type="button" class="carousel__arrows carousel__arrows--left"
                    :class="{'carousel__arrows__shift':showDots}"
                    @click="changeSlide(-1)">
                <span class="icon-left-open"></span>
            </button>

            <button type="button" class="carousel__arrows carousel__arrows--right"
                    :class="{'carousel__arrows__shift':showDots}"
                    @click="changeSlide(1)">
                <span class="icon-right-open"></span>
            </button>
        </slot>

        <ul v-if="showDots" class="carousel__dots">
            <li v-for="i in total" class="carousel__dots__dot"
                :class="{'carousel__dots__dot__active':currentPage == i - 1}"></li>
        </ul>

    </div>
</template>

<script>

    import debounce from 'lodash/debounce'

    const approximatelyEqual = (v1, v2, epsilon) => {
        return Math.abs(v1 - v2) <= epsilon
    };

    const isSSR = typeof window === 'undefined'
    const isClient = !isSSR;

    const SCROLL_DEBOUNCE = 150;
    const RESIZE_DEBOUNCE = 500;

    export default {
        props: {
            hideArrows: {
                type: Boolean,
                default: false
            },
            hideDots: {
                type: Boolean,
                default: false
            }
        },
        data: () => ({
            boundLeft: true,
            boundRight: false,
            slidesWidth: [],
            wrapperScrollWidth: 0,
            wrapperVisibleWidth: 0,
            currentPage: 0,
            currentPos: 0,
            maxPages: 0,
            step: 1,
            observer: null,
            onResizeFn: null,
            onScrollFn: null
        }),
        computed: {
            total() {

                return Object.keys(this.slidesWidth).length;
            },
            showDots() {

                return 1 < Object.keys(this.slidesWidth).length;
            }
        },
        mounted() {

            this.calcOnInit();

            if (isClient) {
                // Assign to new variable and keep reference for removeEventListener (Avoid Memory Leaks)
                this.onResizeFn = debounce(this.calcOnInit, RESIZE_DEBOUNCE);
                this.onScrollFn = debounce(this.calcOnScroll, SCROLL_DEBOUNCE);

                // MutationObserver
                this.attachMutationObserver();

                // Events
                this.$refs.vsWrapper.addEventListener('scroll', this.onScrollFn);

                window.addEventListener('resize', this.onResizeFn, false);
            }
        },
        beforeUnmount() {
            if (isClient) {
                // MutationObserver
                this.observer.disconnect()
                // Events
                this.$refs.vsWrapper.removeEventListener('scroll', this.onScrollFn)
                window.removeEventListener('resize', this.onResizeFn, false)
            }
        },
        methods: {
            calcOnInit() {

                if (!this.$refs.vsWrapper) {

                    return;
                }

                setTimeout(() => {

                    // Timeout here to be sure items size already calculated
                    this.calcWrapperWidth()
                    this.calcSlidesWidth()
                    this.calcCurrentPosition()
                    this.calcCurrentPage()
                    this.calcBounds()
                    this.calcMaxPages()
                }, 200);
            },
            calcOnScroll() {
                if (!this.$refs.vsWrapper) {
                    return
                }
                this.calcCurrentPosition()
                this.calcCurrentPage()
                this.calcBounds()
            },
            calcBounds() {
                // Find the closest point, with 5px approximate.
                const isBoundLeft = approximatelyEqual(this.currentPos, 0, 5)
                const isBoundRight = approximatelyEqual(
                    this.wrapperScrollWidth - this.wrapperVisibleWidth,
                    this.currentPos,
                    5
                );
                if (isBoundLeft) {
                    /**
                     * Reach first item
                     * @event bound-left
                     * @type {Event}
                     */
                    this.boundLeft = true
                } else {
                    this.boundLeft = false
                }
                if (isBoundRight) {
                    /**
                     * Reach last item
                     * @event bound-right
                     * @type {Event}
                     */
                    this.boundRight = true
                } else {
                    this.boundRight = false
                }
            },
            calcWrapperWidth() {
                if (this.$refs.vsWrapper) {
                    this.wrapperScrollWidth = this.$refs.vsWrapper.scrollWidth
                    this.wrapperVisibleWidth = this.$refs.vsWrapper.offsetWidth
                }
            },
            calcSlidesWidth() {

                if (this.$refs.vsWrapper && this.$refs.vsWrapper.children) {

                    const childNodes = [...this.$refs.vsWrapper.children]
                    this.slidesWidth = childNodes.map(node => ({
                        offsetLeft: node.offsetLeft,
                        width: node.offsetWidth
                    }))
                }
            },
            calcCurrentPage() {
                const getCurrentPage = this.slidesWidth.findIndex(slide => {
                    // Find the closest point, with 5px approximate.
                    return approximatelyEqual(slide.offsetLeft, this.currentPos, 5)
                })
                if (getCurrentPage !== -1 && getCurrentPage !== -2) {
                    this.currentPage = getCurrentPage || 0
                }
            },
            calcCurrentPosition() {

                this.currentPos = this.$refs.vsWrapper && this.$refs.vsWrapper.scrollLeft
                    ? this.$refs.vsWrapper.scrollLeft
                    : 0
            },
            calcMaxPages() {
                const maxPos = this.wrapperScrollWidth - this.wrapperVisibleWidth
                this.maxPages = this.slidesWidth.findIndex(({offsetLeft}) => offsetLeft > maxPos) - 1
            },
            calcNextWidth(direction) {

                let nextPageIndex = this.currentPage + direction;

                if ("undefined" === typeof this.slidesWidth[nextPageIndex]) {

                    nextPageIndex = 1 == direction ? 0 : this.slidesWidth[this.slidesWidth.length - 1];
                }

                return this.slidesWidth[nextPageIndex].offsetLeft;
            },
            attachMutationObserver() {
                this.observer = new MutationObserver(() => {
                    this.calcOnInit()
                })
                this.observer.observe(
                    this.$refs.vsWrapper,
                    {attributes: true, childList: true, characterData: true, subtree: true}
                )
            },
            changeSlide(direction) {

                const nextSlideWidth = this.calcNextWidth(direction);

                if ("undefined" === typeof nextSlideWidth) {

                    return
                }

                this.scrollTo(nextSlideWidth)
            },
            scrollTo(left) {

                this.$refs.vsWrapper.scrollLeft = left;
            }
        }
    }
</script>