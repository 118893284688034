<template>
    <div class="container views-order-submit">
        <div class="row" v-if="0===products.length">
            <div class="col-12">
                {{t('noItems')}}
            </div>
        </div>
        <div class="row" v-else>
            <div class="col-12 col-md-7 col-lg-8">

                <!-- Products -->
                <views-order-submit-product v-for="product in products"
                                            v-model="model.products[product.item[productId]]"
                                            class="product"
                                            :key="product.id"
                                            :product="product"
                                            :disabled="processing"
                                            :model="v$.model.products[product.item[productId]]"/>

                <div v-if="products_message"><i>{{products_message}}</i></div>
            </div>
            <div class="col-12 col-md-5 col-lg-4">
                <panel class="panel-secondary">
                    <!-- New Address Form -->
                    <address-form v-if="display.addressform || forceNewAddress"
                                  :hide-cancel="forceNewAddress"
                                  @saved="setDisplay('checkout')"
                                  @cancel="setDisplay('checkout')"/>

                    <!-- Incomplete address form -->
                    <address-form v-if="display.addresscompleteform"
                                  :hide-cancel="true"
                                  :value="model.address"
                                  @saved="setDisplay('checkout');submit()"/>

                    <form v-if="(display.checkout || display.incompleteaddress) && !forceNewAddress" @submit.prevent="base_submit('model', 'submit')">

                        <div v-for="field in checkoutConfigBeforeAddress" :key="field.payload.field">
                            <component v-model="model[field.payload.field]"
                                       :is="checkout.types[field.type]"
                                       :validator="v$.model[field.payload.field]"
                                       :label="field.label ? this.$t('checkout_config.' + field.payload.field + '.' + field.label) : null"
                                       :placeholder="field.placeholder ? this.$t('checkout_config.' + field.payload.field + '.' + field.placeholder) : null"
                                       :disabled="processing||field.disabled"/>
                        </div>

                        <address-selector v-model="model.addressId" :disabled="processing"/>

                        <div v-if="display.incompleteaddress" class="text-center pt-2">
                            <div class="invalid-feedback d-block">
                                {{t('incompleteAddressMessage')}}
                            </div>
                            <button type="button" class="btn btn-sm btn-link"
                                    :disabled="processing"
                                    @click="setDisplay('addresscompleteform')">
                                {{t('completeAddress')}}
                            </button>
                        </div>

                        <div v-if="v$.model.addressId.required.$invalid" class="text-danger small text-center pb-2">
                            {{t('model.addressId.errors.required')}}
                        </div>

                        <div v-if="v$.model.addressId.noAddress.$invalid" class="text-danger small text-center pb-2">
                            {{t('model.addressId.errors.noAddress')}}
                        </div>

                        <div v-if="!denyAddressCreation" class="text-center py-2">
                            <button type="button" class="btn btn-sm btn-link"
                                    :disabled="processing" @click="setDisplay('addressform')">
                                {{t('createNewAddress')}}
                            </button>
                        </div>

                        <div v-for="field in checkoutConfigAfterAddress" :key="field.payload.field">
                            <component v-model="model[field.payload.field]"
                                       :is="checkout.types[field.type]"
                                       :validator="v$.model[field.payload.field]"
                                       :mask="field.mask"
                                       :label="field.label ? this.$t('checkout_config.' + field.payload.field + '.' + field.label) : null"
                                       :placeholder="field.placeholder ? this.$t('checkout_config.' + field.payload.field + '.' + field.placeholder) : null"
                                       :disabled="processing||field.disabled"/>
                        </div>

                        <div class="form-group text-center pt-3">
                            <button type="submit" class="btn btn-sm btn-primary" :disabled="processing">
                                <spinner v-if="processing"/>
                                {{processing ? t('button.submit.processing') : t('button.submit.cta')}}
                            </button>
                        </div>
                    </form>
                </panel>
            </div>
        </div>
    </div>
</template>

<script>

    import {mapGetters} from "vuex"
    import useVuelidate from "@vuelidate/core";
    import MD5 from "crypto-js/md5";
    import get from "lodash/get.js"
    import {required, maxValue, minValue} from "@vuelidate/validators"

    import mixinValidator from "@/helpers/validator";
    import mixinView from "@/mixins/view";
    import mixinCart from "@/mixins/cart";
    import mixinConstraints from "@/mixins/constraints";
    import mixinProducts from "@/mixins/products"
    import {ADDRESS_TFA} from "@/constants";

    import AddressSelector from "@/components/address/AddressSelector";
    import Panel from "@/components/Panel";
    import ModalOrderSubmitConfirm from "@/modal/ModalOrderSubmitConfirm";
    import FormTextbox from "@/components/form/FormTextbox";
    import FormTextarea from "@/components/form/FormTextarea";
    import ModalQuizProfile from "@/modal/ModalQuizProfile";
    import ModalOrderOutOfStock from "@/modal/ModalOrderOutOfStock";
    import ViewsOrderSubmitProduct from "@/views/order/submit/ViewsOrderSubmitProduct";
    import AddressForm from "@/components/address/AddressForm";
    import FormCheckbox from "@/components/form/FormCheckbox";

    const ADDRESS_REFRESH_INTERVAL = 10000;
    const ERROR_OUT_OF_STOCK = "Message: One or more of your requested items is out of stock.";

    export default {
        name: "ViewsOrderSubmitted",
        setup() {
            return {
                v$: useVuelidate()
            }
        },
        components: {
            FormCheckbox,
            AddressForm,
            ViewsOrderSubmitProduct,
            AddressSelector,
            Panel,
            FormTextbox,
            FormTextarea
        },
        mixins: [mixinView, mixinCart, mixinConstraints, mixinProducts, mixinValidator],
        data() {

            const forceNewAddress = 0 === this.$store.getters["user/address/items"].length
                && !this.$store.getters["user/address/denyAddressCreation"];

            return {
                t_path: "views.order.submit",
                processing: false,
                ts: null,
                display: {
                    checkout: !forceNewAddress,
                    addressform: forceNewAddress,
                    addresscompleteform: false,
                    incompleteaddress: false
                },
                model: {
                    addressId: null,
                    address: null,
                    products: {}
                },
                checkout: {
                    types: {
                        textbox: "FormTextbox",
                        textarea: "FormTextarea",
                        checkbox: "FormCheckbox",
                    }
                }
            }
        },
        computed: {
            ...mapGetters({
                productId: "user/cart/id",
                token: "user/token",
                products: "user/cart/items",
                addresses: "user/address/items",
                checkoutConfig: "user/checkout/config",
                checkoutConfigBeforeAddress: "user/checkout/configBeforeAddress",
                checkoutConfigAfterAddress: "user/checkout/configAfterAddress",
                denyAddressCreation: "user/address/denyAddressCreation"
            }),
            forceNewAddress() {

                return 0 === this.addresses.length && !this.denyAddressCreation;
            }
        },
        validations() {

            const model = {
                addressId: {
                    required: value => this.denyAddressCreation && 0 === this.addresses.length || !!value,
                    noAddress: value => value || !(this.denyAddressCreation && 0 === this.addresses.length)
                },
                products: {}
            };

            this.products.forEach(product => model.products[product.item[this.productId]] = {
                required,
                minValue: minValue(1),
                maxValue: maxValue(this.constraints_getProductAvailableAmount(product.item))
            });

            this.checkoutConfig.forEach(field => {

                model[field.payload.field] = this.validator_createDynamicValidationRules(
                    field.validation,
                    {}
                );
            });

            return {
                model,
            }
        },
        methods: {
            setDisplay(enableType) {

                Object.keys(this.display).forEach(type => this.display[type] = type === enableType);
            },
            order() {

                this.processing = true;

                const request = {
                    hash: MD5(`${JSON.stringify(this.model.products)}${this.token}${this.ts}`).toString(),
                    addressId: this.model.addressId,
                    items: Object.keys(this.model.products).map(id => ({
                        [this.productId]: id,
                        quantity: this.model.products[id]
                    })),
                    ...this.checkoutConfig.reduce((result, field) => {

                        result[field.payload.field] = this.model[field.payload.field];

                        return result;
                    }, {})
                };

                if (0 == process.env.VUE_APP_ORDER_MULTIPLE) {

                    // Send only SKU without quantity
                    request.items = request.items.reduce((result, item) => {

                        for (let i = 0; i < item.quantity; i++) {

                            result.push(item.sku);
                        }

                        return result;
                    }, [])
                }

                this.$activity.log("order_submit", {
                    request,
                });

                return this.$store.dispatch("user/orders/post", request);
            },
            process() {

                this.processing = true;

                return this
                    .order()
                    .then(response => {

                        const query = {};

                        if (response.data.results.outOfStockSkus) {

                            this.$activity.log("order_submit_out_of_stock", {
                                outOfStockSkus: response.data.results.outOfStockSkus
                            });

                            query.outOfStockSkus = response.data.results.outOfStockSkus.join(",");
                        }

                        if (response.data.results.orderNotes) {

                            query.notes = JSON.stringify(response.data.results.orderNotes);
                        }

                        // Navigate to order submitted page
                        this.$router.push({name: "order.submitted", query});
                    })
                    .catch(error => {

                        if (!error.response) {

                            return this.$toast.error(this.t("errors.requestError"), {
                                timeout: 5000
                            });
                        }

                        if (409 == error.response.status) {

                            // Clear cart
                            this.$store.dispatch("user/cart/clear");

                            // Navigate to order submitted page
                            return this.$router.push({name: "order.submitted"});
                        }

                        if (400 == error.response.status) {

                            const errorMessage = get(error, "response.data.results.0.msg");

                            // Check for it's out of stock issue
                            if (errorMessage && null !== errorMessage.match(ERROR_OUT_OF_STOCK)) {

                                // Delete items from the cart

                                // Display items out of stock
                                return this.$modal.show({
                                    component: ModalOrderOutOfStock,
                                    props: {
                                        items: get(error, "response.data.results.0.data")
                                            .map(sku => {

                                                // Find item for showing in modal
                                                const item = this.cart_find({sku});

                                                // Remove item from the cart
                                                this.cart_remove({sku});

                                                return item && item.item ? item.item : null;
                                            })
                                            .filter(item => !!item)
                                    }
                                });
                            }
                        }

                        this.base_error(error);
                    })
                    .finally(() => this.processing = false);
            },
            submit() {

                this.processing = false;

                if (this.addresses.find(address => address._id == this.model.addressId).incomplete) {

                    return this.setDisplay("incompleteaddress");
                }

                if (this.$store.getters["user/profile/forceQuizProfile"]) {

                    return this.$modal.show({
                        component: ModalQuizProfile,
                        esc: false,
                        lg: true,
                        props: {
                            addressId: this.model.addressId
                        }
                    }).then(() => this.submit());
                }

                return this.$modal
                    .show({
                        component: ModalOrderSubmitConfirm,
                        props: {
                            addressId: this.model.addressId
                        }
                    })
                    .then(() => this.process());
            },
        },
        watch: {
            checkoutConfig: {
                immediate: true,
                deep: true,
                handler(config) {

                    config.forEach(field => {

                        if ("undefined" === typeof this.model[field.payload.field]) {

                            this.model[field.payload.field] = null;
                        }
                    });
                }
            },
            products: {
                immediate: true,
                deep: true,
                handler(products) {

                    this.model.products = products.reduce((result, product) => {

                        result[product.item[this.productId]] = product.amount;

                        return result;
                    }, {});
                }
            },
            "model.products": {
                deep: true,
                handler(products) {

                    Object.keys(products).forEach(productId => {

                        if (!this.v$.model.products[productId].$invalid) {

                            this.cart_setAmount(
                                {[this.$store.getters["user/cart/id"]]: productId},
                                products[productId]
                            );
                        }
                    });
                }
            },
            "model.addressId": {
                immediate: true,
                handler() {

                    this.model.address = this.$store.getters["user/address/findItem"](this.model.addressId);
                }
            }
        },
        mounted() {

            this.ts = new Date().getTime();

            if (ADDRESS_TFA) {

                this.$store.dispatch("user/address/refresh", {start: true, interval: ADDRESS_REFRESH_INTERVAL});
            }
        },
        beforeUnmount() {

            if (ADDRESS_TFA) {

                this.$store.dispatch("user/address/refresh", false);
            }
        }
    }
</script>