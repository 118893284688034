import ViewsRouter from '@/views/ViewsRouter'
import ViewsSurveySubmitted from "@/views/survey/ViewsSurveySubmitted";
import ViewsSurveyDelivery from "@/views/survey/ViewsSurveyDelivery";
import ViewsSurveyDeliveryFaq from "@/views/survey/ViewsSurveyDeliveryFaq";
import ViewsSurveyCustomer from "@/views/survey/ViewsSurveyCustomer";

export default {
    routes: [{
        path: "/survey",
        name: "survey-parent",
        component: ViewsRouter,
        children: [{
            path: "submitted",
            name: "delivery.submitted",
            component: ViewsSurveySubmitted
        }, {
            path: "delivery",
            name: "survey.delivery",
            component: ViewsSurveyDelivery
        }, {
            path: "deliveryfaq",
            name: "survey.deliveryfaq",
            component: ViewsSurveyDeliveryFaq
        }, {
            path: "customer",
            name: "survey.customer",
            component: ViewsSurveyCustomer
        }]
    }]
}
