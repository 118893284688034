import {getStorage} from "@/api/storage";

const STORAGE_KEY = "quiz_equipment";

export default {
    namespaced: true,
    state: {
        model: {}
    },
    getters: {
        model: state => state.model,
        storage: () => userId => getStorage().get(STORAGE_KEY, {})[userId]
    },
    mutations: {
        setModel: (state, model) => state.model = model
    },
    actions: {
        fetch(context, model) {

            if ("undefined" === typeof model) {

                const storage = getStorage();
                const userId = context.rootGetters["user/id"];

                model = storage.get(STORAGE_KEY) && storage.get(STORAGE_KEY)[userId]
                    ? storage.get(STORAGE_KEY)[userId]
                    : {};
            }

            context.commit("setModel", model);
        },
        setModel(context, model) {

            context.commit("setModel", model);
            context.dispatch("updateStorage");
        },
        clear(context) {

            context.commit("setModel", {});
            context.dispatch("updateStorage");
        },
        updateStorage(context) {

            const storage = getStorage();
            const result = storage.get(STORAGE_KEY, {});

            storage.set(STORAGE_KEY, {
                ...result,
                [context.rootGetters["user/id"]]: context.state.model
            })
        },
    }
}