/* eslint class-methods-use-this: off */

let ls = {};

class MemoryStorageInterface {
    constructor() {
        Object.defineProperty(this, 'length', {
            get() {
                return Object.keys(ls).length;
            },
        });
    }

    getItem(name) {
        return name in ls ? ls[name] : null;
    }

    setItem(name, value) {
        ls[name] = value;

        return true;
    }

    removeItem(name) {
        const found = name in ls;

        if (found) {
            return delete ls[name];
        }

        return false;
    }

    clear() {
        ls = {};

        return true;
    }

    key(index) {
        const keys = Object.keys(ls);

        return typeof keys[index] !== 'undefined' ? keys[index] : null;
    }
}

export default new MemoryStorageInterface();
