import {getStorage} from "@/api/storage";
import {v4 as uuidv4} from "uuid";

export const AUTOLOGIN_SESSION_ID = "autologin_session_id";

export function startAutoLogin(params) {

    const form = document.createElement("form");
    const sessionId = uuidv4();
    const url = new URL(`${window.location.origin}/login/sso`);

    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));

    getStorage().set(AUTOLOGIN_SESSION_ID, sessionId)

    form.setAttribute("method", "POST");
    form.setAttribute("action", `${process.env.VUE_APP_PLATFORM_API_URL}/api/v2/sso/login`);

    const hidden = document.createElement("input");

    hidden.setAttribute("type", "hidden")
    hidden.setAttribute("name", "RelayState")
    hidden.setAttribute("value", JSON.stringify({
        url: url.href,
        html: false,
        sessionId,
    }));

    form.appendChild(hidden);

    document.body.appendChild(form);

    form.submit();

    return true;
}