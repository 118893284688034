import {platform} from "@/api/platform";
import {retryRequest} from "@/helpers/request";
import {getBranchById, getParentsChain} from "@/helpers/tree";

export default {
    namespaced: true,
    state: {
        loading: false,
        items: [],
        active: null,
        grouping: null
    },
    getters: {
        loading: state => state.loading,
        items: state => state.items,
        find: state => id => getBranchById(state.items, id) || state.items[0],
        active: state => getBranchById(state.items, state.active),
        grouping: state => state.grouping,
        chain: state => !state.active
            ? []
            : getParentsChain(state.items, getBranchById(state.items, state.active))
    },
    mutations: {
        setLoading: (state, loading) => state.loading = loading,
        setItems: (state, tree) => state.items = tree,
        setActive: (state, active) => state.active = active,
        setGrouping: (state, grouping) => state.grouping = grouping
    },
    actions: {
        fetch(context, {grouping, active}) {

            context.commit("setLoading", true);
            context.commit("setGrouping", grouping);

            return retryRequest(() => platform.get(`/api/v2/groupingList/${grouping}`))
                .then(response => {

                    context.commit("setItems", response.data.results.groupings);

                    if (active) {

                        context.commit("setActive", active);
                    }

                    return {
                        active: context.getters["active"],
                        items: context.getters["items"]
                    }
                })
                .finally(() => context.commit("setLoading", false))
        },
        setActive(context, active) {

            context.commit("setActive", active);
        }
    }
}