export default {
    namespaced: true,
    state: {
        items: [{
            id: "Monitor",
            name: "db.providedProducts.monitor.name",
            image: "https://via.placeholder.com/150x150.png?text=Monitor"
        }, {
            id: "Keyboard",
            name: "db.providedProducts.keyboard.name",
            image: "https://via.placeholder.com/150x150.png?text=Keyboard"
        }, {
            id: "Mouse",
            name: "db.providedProducts.mouse.name",
            image: "https://via.placeholder.com/150x150.png?text=Mouse"
        }, {
            id: "Webcam",
            name: "db.providedProducts.webcam.name",
            image: "https://via.placeholder.com/150x150.png?text=Webcam"
        }, {
            id: "Headset",
            name: "db.providedProducts.headset.name",
            image: "https://via.placeholder.com/150x150.png?text=Headeset"
        }, {
            id: "Docking Station",
            name: "db.providedProducts.dockingStation.name",
            image: "https://via.placeholder.com/150x150.png?text=DockingStation"
        }]
    },
    getters: {
        items: state => state.items
    }
}