export default {
    methods: {
        quiz_values_doesComponentHaveValue(component) {

            if (!this.model[component]) {

                return false;
            }

            if (Array.isArray(this.model[component])) {

                return 0 < this.model[component].length
            }

            return !!this.model[component];
        },
        quiz_values_getComponentValue(component) {

            return this.model[component];
        }
    }
}