export default {
    title: "Helpdesk",
    header: "Helpdesk",
    model: {
        email: {
            label: "User email",
            placeholder: "Please fill user email"
        }
    },
    cta: "Submit",
    processing: "Loading"
}