<template>
    <page-message>
        <template #header>{{t('header')}}</template>
        <template #html>
            <p v-html="t('message')"></p>
        </template>
    </page-message>
</template>

<script>
    import PageMessage from "@/components/page/PageMessage";

    export default {
        name: "ViewsMaintenance",
        components: {
            PageMessage
        },
        data() {

            return {
                t_path: "views.maintenance"
            }
        },
    }
</script>