import {create} from "./_api";

const api = create();

export const order = api;

export default {
    api,
    install(app, options = {}) {

        api.defaults.baseURL = options.baseURL;
        api.defaults.params = options.params;

        app.provide("$order", api);
        app.config.globalProperties.$order = api;
    }
}