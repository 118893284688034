<template>
    <div>

        <div class="font-weight-bold pb-3">{{t('title')}}</div>

        <loading v-if="loading"></loading>

        <div v-else>

            <div v-if="0 == items.length">
                {{t('noItems')}}
            </div>

            <div v-for="item in items" class="pb-2">

                <router-link :to="{name:'products', params: {grouping:'recommendation', grouping_id: item.id}}">
                    {{item.name}}
                </router-link>
            </div>

        </div>

    </div>
</template>

<script>

    import {retryRequest} from "@/helpers/request";

    export default {
        name: "AdvisorRecommendations",
        data() {

            return {
                t_path: "components.advisor.recommendations",
                loading: false,

                items: []
            }
        },
        methods: {
            fetch() {

                this.loading = true;

                return retryRequest(() => this.$platform.get("/api/v2/groupingList/recommendation"))
                    .then(response => this.items = response.data.results.groupings)
                    .finally(() => this.loading = false)
            }
        },
        mounted() {
            this.fetch();
        },
    }
</script>