export default {
    login: "Login",
    logout: "Logout",
    home: "Home",
    user: {
        profile: {
            edit: "Edit Profile"
        }
    },
    approvals: {
        delegates: "Delegates"
    }
}