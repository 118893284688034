<template>
    <nav class="navbar navbar-expand-md navbar-light bg-white">
        <span class="navbar-brand"></span>
    </nav>
</template>

<script>

    export default {
        name: "PageNavbarMaintenance"
    }
</script>