<template>
    <div class="categories-list-category">
        <router-link class="thumbnail" :to="{name:'products', params: {grouping:this.grouping, grouping_id: category.id}}">
            <img class="image" :src="image" :alt="name" />
        </router-link>
        <router-link class="title" :to="{name:'products', params: {grouping:this.grouping, grouping_id: category.id}}">
            {{name}}
        </router-link>
    </div>
</template>

<script>

    import {getTranslationValue} from "@/i18n/utils/lang_overrides";

    import Panel from "@/components/Panel";

    export default {
        name: "CategoriesListCategory",
        components: {Panel},
        props: {
            category: {
                type: Object,
                required: true
            },
            grouping: {
                type: String,
                required: true
            }
        },
        computed: {
            name() {

                return getTranslationValue(this.category, ["name"], this.$i18n);
            },
            image() {

                return this.category.image;
            }
        }
    }
</script>